import React from 'react'
import './CompatibilityContext.scss'

// This is the "compatibility results" section on the right-hand side.
function CompatibilityContext (props) {
  const list = props.context.clashes.map(clash => {
    const text = `${clash.party1} - ${clash.reason} ${clash.party2}`
    return <li key={text}>{text}</li>
  })

  return (
    <div className='compatibility-context'>
      <div className='name'>{props.context.name}</div>
      <ul>{list}</ul>
    </div>
  )
}

export default CompatibilityContext
