import React from 'react'
import './LabelInput.scss'

// An input element with a label.
function LabelInput (props) {
  const classes = ['label-input', props.required ? ' required' : ''].join('')

  let elem = props.children

  if (!props.children) {
    elem = (
      <input
        className="input"
        type={props.type}
        placeholder={props.label}
        disabled={props.disabled || false}
        value={props.value}
        onChange={props.onChange}
      />
    )
  }

  return (
    <label className={classes}>
      <span className='label'>{props.label}</span>
      {elem}
    </label>
  )
}

export default LabelInput
