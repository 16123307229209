/* eslint prefer-promise-reject-errors: 0 */

import React from 'react'
import './InjectedPaymentForm.scss'

import {
  CardNumberElement, CardCVCElement, CardExpiryElement, injectStripe
} from 'react-stripe-elements'

// Components:
import InfoBox from '../../common/InfoBox/InfoBox'
import LabelInput from '../../common/LabelInput/LabelInput'
import PromoCodeSearch from '../PromoCodeSearch/PromoCodeSearch'
import SimpleButton from '../../common/SimpleButton/SimpleButton'
import SimpleRow from '../../common/SimpleRow/SimpleRow'

// This component represents the "visible" payment form, without much logic.
class PaymentForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  handleSubmit = () => {
    const stripeApiRequest = new Promise((resolve, reject) => {
      this.props.stripe.createToken({ type: 'card' }).then(
        api => api.error ? reject(api.error.message) : resolve(api.token),
        () => reject('Unknown Stripe error')
      )
    })

    this.props.onSubmit(stripeApiRequest)
  }

  render () {
    const buttonText = this.props.buttonText || 'Update Payment Details'

    // Show this if `onPromoCode()` is given, or if `forcePromoCode` forces it:
    const promoCode = (this.props.onPromoCode || this.props.forcePromoCode) ? (
      <LabelInput label="Promo code">
        <PromoCodeSearch onSubmit={this.props.onPromoCode} />
      </LabelInput>
    ) : null

    return (
      <div className="injected-payment-form">
        <InfoBox>{this.props.reason}</InfoBox>
        <div className="fields">
          {(<LabelInput label="Payment method" value="Credit or debit card" disabled={true} />) && null}
          <LabelInput label="Card number">
            <CardNumberElement className="input" />
          </LabelInput>
          <SimpleRow>
            <LabelInput label="Expiry date">
              <CardExpiryElement className="input" />
            </LabelInput>
            <LabelInput label="Security code">
              <CardCVCElement className="input" />
            </LabelInput>
          </SimpleRow>
          {promoCode}
        </div>
        <small>
          By clicking {buttonText} you agree to our
          terms. All payments are handled by Stripe, a third party company.
          You will automatically be charged for your subscription on a monthly
          basis until you decide to cancel.
        </small>
        <SimpleButton
          expand={true}
          onClick={this.handleSubmit}
          text={buttonText}
        />
        {this.props.children}
      </div>
    )
  }
}

export default injectStripe(PaymentForm)
