import React from 'react'
import './OrganisationForm.scss'
// Components:
import LabelInput from '../../../common/LabelInput/LabelInput'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimpleRow from '../../../common/SimpleRow/SimpleRow'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// Edit Organisation Form.
class OrganisationForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: null,
      loading: true
    }
  }

  componentDidMount () {
    AccountManagement.fetchOrganisation(this.props.token).then(
      json => this.setState({ loading: false, name: json.name }),
      error => this.props.onError(error)
    )
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ loading: true })

    AccountManagement.updateOrganisation(this.state, this.props.token).then(
      json => {
        this.props.onSuccess(json)
        this.props.successMessage('Organisation has been updated.')
        this.setState({ loading: false })
      },
      error => {
        this.props.onError(error)
        this.setState({ loading: false })
      })
  }

  render () {
    const disabled = this.state.loading || !this.props.user.owner

    return (
      <form className="organisation-form" onSubmit={this.handleSubmit}>
        <SimpleRow>
          <LabelInput
            label="Name"
            required={true}
            value={this.state.name || ''}
            disabled={disabled}
            onChange={this.handleNameChange}
          />
        </SimpleRow>
        <SimpleButton text="Update" expand={true} disabled={disabled} />
      </form>
    )
  }
}

export default OrganisationForm
