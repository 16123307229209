import React from 'react'
import './SettingsPage.scss'
// Components:
import BillingEditor from './BillingEditor/BillingEditor'
import BillingHistory from './BillingHistory/BillingHistory'
import BillingStatus from './BillingStatus/BillingStatus'
import CancelButton from './CancelButton/CancelButton'
import LogoutButton from '../LogoutButton/LogoutButton'
import OrganisationForm from './OrganisationForm/OrganisationForm'
import PageWithNavbar from '../../common/PageWithNavbar/PageWithNavbar'
import PleaseWait from '../../common/PleaseWait/PleaseWait'
import SimpleSection from '../../common/SimpleSection/SimpleSection'
import SimpleButton from '../../common/SimpleButton/SimpleButton'
import SubscriptionForm from './SubscriptionForm/SubscriptionForm'
import UserForm from './UserForm/UserForm'
import UserList from './UserList/UserList'
import InvoiceInfo from './InvoiceInfo/InvoiceInfo'
// APIs:
import AccountManagement from '../../apis/AccountManagement'

// This represents the settings page as a whole.
class SettingsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      currentUser: null,
      selectedInvoice: null,
      licenceCount: null,
      cancellationPending: false,
      historyMode: false,
      editPlanMode: false,
      loading: true
    }
  }

  componentDidMount () {
    AccountManagement.activeUser(this.props.token).then(
      json => this.setState({ currentUser: json }),
      this.handleError
    )
    AccountManagement.fetchSubscription(this.props.token).then(
      json => this.setState({
        cancellationPending: json.cancellationPending,
        licenceCount: json.licenceCount || 0
      }),
      this.handleError
    )
  }

  handleError = (error) => {
    this.setState({ loading: false })
    this.props.onError(error)
  }

  handleSuccess = () => {
    this.props.onError(null)
  }

  successMessage = (e) => {
    this.props.onSuccess(e)
  }

  handleDeletion = () => {
    window.location.reload()
  }

  handleUpdateLicenceCount = (newLicenceCount) => {
    AccountManagement.updateLicences({ licenceCount: newLicenceCount }, this.props.token).then(
      json => {
        this.handleSuccess(json)
        this.successMessage('Licence count has been updated.')
        this.setState({
          licenceCount: newLicenceCount,
          editPlanMode: false
        })
      },
      error => this.handleError(error)
    )
  }

  handleSelectInvoice = (invoice) => {
    this.setState({ selectedInvoice: invoice })
  }

  navbarItems = () => {
    return (
      <React.Fragment>
        <a className="cancel-link" href="#top" onClick={this.props.onHazculator}>
          Back to HAZculator
        </a>
        <LogoutButton onClick={this.props.onSignOut} />
      </React.Fragment>
    )
  }

  cancellationMessage = () => (
    <div className="cancel-button cancel-message">Account scheduled for deletion</div>
  )

  cancelButton = () => (
    <CancelButton
      user={this.state.currentUser}
      token={this.props.token}
      onSuccess={this.handleDeletion}
      onError={this.props.onError}
      disabled={this.state.cancellationPending}
    />
  )

  showHistory = () => this.setState({ historyMode: true })
  hideHistory = () => this.setState({ historyMode: false })
  showEditPlan = () => this.setState({ editPlanMode: true })

  generalSection = () => (
    <div className="general">
      <SimpleSection title="Organisation Details">
        <OrganisationForm
          user={this.state.currentUser}
          token={this.props.token}
          onSuccess={this.handleSuccess}
          successMessage={this.successMessage}
          onError={this.props.onError}
        />
      </SimpleSection>
      <SimpleSection title="Your details">
        <UserForm
          includePassword={true}
          user={this.state.currentUser}
          token={this.props.token}
          onSuccess={this.handleSuccess}
          successMessage={this.successMessage}
          onError={this.props.onError}
        />
      </SimpleSection>
      <SimpleSection>
        {this.state.cancellationPending ? this.cancellationMessage() : this.cancelButton()}
      </SimpleSection>
    </div>
  )

  billingSection = () => {
    let info = null

    if (this.state.historyMode) {
      info = (
        <BillingHistory
          token={this.props.token}
          onSelect={this.handleSelectInvoice}
          onCancel={this.hideHistory}
          onError={this.props.onError}
        />
      )
    } else if (this.state.editPlanMode) {
      info = (
        <BillingEditor
          token={this.props.token}
          licenceCount={this.state.licenceCount}
          onSubmit={this.handleUpdateLicenceCount}
          onError={this.props.onError}
        />
      )
    } else {
      info = (
        <BillingStatus
          token={this.props.token}
          licenceCount={this.state.licenceCount}
          onHistory={this.showHistory}
          onEdit={this.state.cancellationPending ? null : this.showEditPlan}
          onError={this.props.onError}
        />
      )
    }

    return (
      <div className="billing-container">
        <div className="billing">{info}</div>
        <div className="billing">
          <SimpleSection title="Payment Details">
            <SubscriptionForm
              user={this.state.currentUser}
              token={this.props.token}
              onSuccess={this.handleSuccess}
              successMessage={this.successMessage}
              onError={this.props.onError}
            />
          </SimpleSection>
        </div>
      </div>
    )
  }

  invoiceSection = () => (
    <div className="invoices">
      <SimpleSection title="Receipt">
        <InvoiceInfo
          invoice={this.state.selectedInvoice}
        />
        <SimpleButton
          onClick={() => this.setState({ selectedInvoice: null })}
          text="Back"
          theme="muted invoice-back-button"
        />
      </SimpleSection>
    </div>
  )

  usersSection = () => (
    <div className="users">
      <UserList
        user={this.state.currentUser}
        token={this.props.token}
        onSuccess={this.handleSuccess}
        successMessage={this.successMessage}
        onError={this.props.onError}
        licenceCount={this.state.licenceCount}
      />
    </div>
  )

  render () {
    let page = null

    if (!this.state.currentUser) {
      page = <PleaseWait />
    } else if (!this.state.currentUser.owner) {
      page = this.generalSection()
    } else if (this.state.selectedInvoice) {
      page = this.invoiceSection()
    } else {
      page = (
        <React.Fragment>
          {this.generalSection()}
          {this.billingSection()}
          {this.usersSection()}
        </React.Fragment>
      )
    }

    return (
      <PageWithNavbar
        error={this.props.error}
        success={this.props.success}
        navbarItems={this.navbarItems()}
      >
        <div className="settings-page">{page}</div>
      </PageWithNavbar>
    )
  }
}

export default SettingsPage
