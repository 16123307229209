import React from 'react'
import './GenericSection.scss'

// A collapsible "section", consisting of a "header" bar and a "content" panel.
function GenericSection (props) {
  return (
    <div className="generic-section">
      <div className={props.expanded ? 'head open' : 'head'} onClick={props.onDropdownToggle}>
        {props.head}
      </div>
      {props.expanded && <div className="body">{props.body}</div>}
    </div>
  )
}

export default GenericSection
