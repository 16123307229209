import React from 'react'
import './CreateSubscription.scss'
// Components:
import CostBreakdown from '../../CostBreakdown/CostBreakdown'
import FooterLinks from '../../FooterLinks/FooterLinks'
import SignUpProgress from '../SignUpProgress/SignUpProgress'
import SimplePage from '../../SimplePage/SimplePage'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import StripeContainer from '../../Stripe/StripeContainer'

// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// This handles the first screen of the sign-up process.
class CreateSubscription extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      licenceCount: 0,
      promoCode: null,
      coupon: null
    }
  }

  componentDidMount () {
    AccountManagement.fetchSubscription(this.props.token).then(
      json => this.setState({ licenceCount: json.licenceCount })
    )
  }

  handlePromoCodeChange = (code, serverRequest) => {
    serverRequest.then(
      coupon => this.setState({ error: null, promoCode: code, coupon: coupon }),
      error => this.setState({ error: `${error} "${code}"` })
    )
  }

  handleSuccess = (json) => {
    this.setState({ error: null })
    if (json.status === 'succeeded') {
      this.props.onComplete()
    }
  }

  handleError = (error) => this.setState({ error })

  render = () => (
    <SimplePage
      error={this.state.error}
      title="Payment Details"
      header={<SignUpProgress step={2} />}
      footer={<FooterLinks />}
      wide={true}
    >
      <div className="create-subscription">
        <div className="payment-form">
          <StripeContainer
            token={this.props.token}
            promoCode={this.state.promoCode}
            onPromoCodeChange={this.handlePromoCodeChange}
            onSuccess={this.handleSuccess}
            successMessage={this.props.onSuccess}
            onError={this.handleError}
          >
            <SimpleButton
              expand={true}
              onClick={this.props.onCancel}
              text="Return to Login"
              theme="muted"
            />
          </StripeContainer>
        </div>
        <div className="payment-info">
          <CostBreakdown
            token={this.props.token}
            coupon={this.state.coupon}
            onError={this.handleError}
            totalLabel="Due today"
            licenceCount={this.state.licenceCount}
          />
        </div>
      </div>
    </SimplePage>
  )
}

export default CreateSubscription
