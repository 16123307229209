import React from 'react'
import './EditPlan.scss'

// Utilities
import CostCalculator from '../CostCalculator'

const EditPlan = (props) => (
  <React.Fragment>
    <div className="cost-label">Base software cost <span className="text-sm">- includes admin user</span></div>
    <div className="cost-row">
      {CostCalculator.currency(props.costs)}
      {CostCalculator.baseCost(props.costs).toFixed(2)}
      <span className="base-number">1</span>
    </div>
    <div className="cost-label">Add additional licences</div>
    <div className="cost-row">
      {CostCalculator.currency(props.costs)}
      {CostCalculator.userCost(props.costs).toFixed(2)}
      <span className="text-sm"> per licence</span>
      <input
        type="number"
        min={0}
        max={100}
        value={props.licenceCount.toString()}
        onChange={props.handleChange}
        className="licence-count-box"
      />
    </div>
  </React.Fragment>
)

export default EditPlan
