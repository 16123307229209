import React from 'react'
import './SimpleSection.scss'

// A simple "section" container.
function SimpleSection (props) {
  return (
    <div className="simple-section">
      <div className="head">{props.title}</div>
      <div className="body">{props.children}</div>
    </div>
  )
}

export default SimpleSection
