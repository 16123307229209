// imports:
import React from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
// Components:
import InjectedPaymentForm from './InjectedPaymentForm'
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'

function StripePayment (props) {
  const apiKey = process.env.REACT_APP_STRIPE_API_KEY

  return (
    <ErrorBoundary>
      <StripeProvider apiKey={apiKey}>
        <Elements>
          <InjectedPaymentForm {...props} />
        </Elements>
      </StripeProvider>
    </ErrorBoundary>
  )
}

export default StripePayment
