import React from 'react'
import './CompatibilityDetails.scss'
// Components:
import CompatibilityDetail from './CompatibilityDetail'

// This is the "compatibility results" section on the right-hand side.
class CompatibilityDetails extends React.Component {
  compatibilityDetail = (item, idx) => (
    <CompatibilityDetail
      detail={item}
      idx={idx}
      handleDropdownToggle={this.props.handleDropdownToggle}
    />
  )

  compatibilityDetailsList = () => (
    this.props.items.map((item, idx) => this.compatibilityDetail(item, idx))
  )

  render () {
    if (this.props.items.length === 0) { return null }
    return (
      <div className="compatibility-details">
        <div className="title">Clash Details</div>
        {this.compatibilityDetailsList()}
      </div>
    )
  }
}

export default CompatibilityDetails
