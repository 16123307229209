import React from 'react'
import './ErrorMessage.scss'

// This simply displays the provided error message in a standard way.
function ErrorMessage (props) {
  if (!props.text) { return null }
  return <div className="error-message">{props.text}</div>
}

export default ErrorMessage
