/* eslint prefer-promise-reject-errors: 0 */

function queryStringFrom (data) {
  if (!data) { return '' }

  let result = new URLSearchParams()
  Object.keys(data).forEach(k => { if (data[k]) result.set(k, data[k]) })
  return String(result)
}

function itemListParams (data) {
  let result = {}
  data.forEach((item, i) => {
    result[`imdg[${i}]`] = item.imdg
    result[`adr[${i}]`] = item.adr
    result[`lq[${i}]`] = item.limitedQuantity
  })
  return result
}

function f (jwt, path, params) {
  return new Promise((resolve, reject) => {
    const url = [process.env.REACT_APP_HAZCULATOR_SERVER, path].join('/')
    const authorization = ['Bearer', jwt.access_token].join(' ')

    let xhr = new XMLHttpRequest()
    xhr.open('GET', [url, queryStringFrom(params)].join('?'))
    xhr.setRequestHeader('Authorization', authorization)
    xhr.send()

    xhr.onload = () => {
      const json = JSON.parse(xhr.response)
      xhr.status === 200 ? resolve(json) : reject(json.error)
    }

    xhr.onerror = () => { reject('The HAZculator server is offline') }
    xhr.onabort = () => { reject('The HAZculator request was aborted') }
    xhr.ontimeout = () => { reject('The HAZculator request timed out') }
  })
}

export default {
  versions: (jwt) => f(jwt, 'versions'),
  search: (jwt, v, filter) => f(jwt, 'search', { version: v, filter: filter }),
  variants: (jwt, v, id) => f(jwt, 'variants', { version: v, id: id }),
  details: (jwt, imdg, adr) => f(jwt, 'details', { imdg: imdg, adr: adr }),
  compatibility: (jwt, items) => f(jwt, 'compatibility', itemListParams(items))
}
