import React from 'react'
import './CostBreakdown.scss'
// APIs:
import AccountManagement from '../../apis/AccountManagement'
// Utilities:
import CostCalculator from '../CostCalculator'
import CouponHelper from '../CouponHelper'

// Shows how we calculate the subscription cost.
class CostBreakdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = { costs: null }
  }

  componentDidMount () {
    AccountManagement.priceTiers().then(
      json => this.setState({ costs: json }),
      this.props.onError
    )
  }

  baseCost = () => {
    if (!this.state.costs) { return '' }

    return [
      CostCalculator.currency(this.state.costs),
      CostCalculator.baseCost(this.state.costs)
    ].join('')
  }

  userCost = () => {
    if (!this.state.costs) { return '' }

    return [
      CostCalculator.currency(this.state.costs),
      CostCalculator.userCost(this.state.costs).toFixed(2)
    ].join('')
  }

  usersCost = () => {
    if (!this.state.costs) { return '' }

    return [
      CostCalculator.currency(this.state.costs),
      CostCalculator.usersCost(this.state.costs, this.quantity()).toFixed(2)
    ].join('')
  }

  totalCost = () => {
    if (!this.state.costs) { return '' }

    const value = CostCalculator.totalCost(
      this.state.costs,
      this.quantity(),
      this.props.coupon
    )

    return `${CostCalculator.currency(this.state.costs)} ${value.toFixed(2)}`
  }

  couponDuration = () => (
    this.props.coupon ? CouponHelper.durationText(this.props.coupon) : ''
  )

  couponDiscount = () => (
    this.props.coupon ? CouponHelper.discountText(this.props.coupon) : ''
  )

  quantity = () => !this.props.licenceCount ? 0 : this.props.licenceCount

  render = () => (
    <div className="cost-breakdown">
      <div className="title">Your subscription</div>
      <div className="items">
        <div className="item">
          <div className="quantity"></div>
          <div className="description">Software price</div>
          <div className="price">{this.baseCost()}</div>
        </div>
        <div className="item">
          <div className="quantity">{String(this.quantity())}</div>
          <div className="description">Additional user licence</div>
          <div className="price">{this.usersCost()}</div>
        </div>
      </div>
      {this.props.coupon ? (
        <div className="coupon">
          <div className="description">{this.props.coupon.name}</div>
          <div className="duration">{this.couponDuration()}</div>
          <div className="price">{this.couponDiscount()}</div>
        </div>
      ) : null}
      <div className="total">
        <div className="description">{this.props.totalLabel}</div>
        <div className="price">{this.totalCost()}</div>
      </div>
    </div>
  )
}

export default CostBreakdown
