import React from 'react'
import './PleaseWait.scss'

// A placeholder for when something is loading.
function PleaseWait (props) {
  return <div className="please-wait">
    <div className="spinner">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </div>
}

export default PleaseWait
