import React from 'react'
// Components:
import DataList from '../../common/DataList/DataList'
import GenericSection from '../../common/GenericSection/GenericSection'
// Utilities:
import PrettyPrint from './PrettyPrint'

// This represents a single item within the list of currently-added items.
class HazardousItemAdr extends React.Component {
  state = { expanded: false }

  handleDropdownToggle = () => (
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  )

  data = () => {
    let { adr } = this.props

    let data = {
      'PSN': adr.psn,
      'Classes': PrettyPrint.classes(adr),
      'Packing Group': PrettyPrint.packingGroup(adr),
      'Tunnel Code': PrettyPrint.tunnelCodeName(adr),
      'Limited Quantity': PrettyPrint.limitedQuantity(adr),
      'Excepted Quantity': PrettyPrint.exceptedQuantity(adr),
      'Transport Category': PrettyPrint.transportCategory(adr),
      'Tunnel Code Info': PrettyPrint.tunnelCodeInfo(adr),
      'Special Provisions': PrettyPrint.specialProvisions(adr),
      'Foodstuffs Segregation': PrettyPrint.foodstuffs(adr),
      'V codes': PrettyPrint.adrCodes(adr.vCodes),
      'CV codes': PrettyPrint.adrCodes(adr.cvCodes),
      'S codes': PrettyPrint.adrCodes(adr.sCodes),
      'Classification Code': PrettyPrint.classificationCode(adr)
    }

    if (adr.restrictions) {
      Object.assign(data, { 'Carriage prohibited': '⚠️' })
    }

    if (adr.notSubject) {
      Object.assign(data, { 'Not subject to ADR': adr.notSubject })
    }

    return data
  }

  text = [
    this.props.adr.revision,
    PrettyPrint.adrDescription(this.props.un, this.props.adr)
  ].join(': ')

  head = () => (
    <div className="adr-head">
      {this.text}
    </div>
  )

  render () {
    return (
      <GenericSection
        head={this.head()}
        body={<DataList data={this.data()} />}
        expanded={this.state.expanded}
        onDropdownToggle={this.handleDropdownToggle}
      />
    )
  }
}

export default HazardousItemAdr
