/* eslint prefer-promise-reject-errors: 0 */

function keycloakURL (path, params) {
  const url = [
    process.env.REACT_APP_KEYCLOAK_SERVER,
    'auth/realms',
    process.env.REACT_APP_KEYCLOAK_REALM,
    path
  ].join('/')

  // Most URLs send POST-style params, so no GET-style values are specified:
  if (!params) { return url }

  // However, a few URLs require GET-style values, so include them if needed:
  let result = new URLSearchParams()
  Object.keys(params).forEach(k => { if (params[k]) result.set(k, params[k]) })
  return [url, String(result)].join('?')
}

function keycloakXHR (path, params) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.open('POST', keycloakURL(path))
    xhr.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    )

    let data = new URLSearchParams()
    Object.keys(params).forEach(k => { if (params[k]) data.set(k, params[k]) })
    xhr.send(data)

    xhr.onload = () => {
      const okay = xhr.status === 200 || xhr.status === 204
      const json = xhr.response ? JSON.parse(xhr.response) : null
      okay ? resolve(json) : reject(json.error_description)
    }

    xhr.onerror = () => { reject('The authorisation server is offline') }
    xhr.onabort = () => { reject('The authorisation request was aborted') }
    xhr.ontimeout = () => { reject('The authorisation request timed out') }
  })
}

export default {
  signIn (username, password) {
    return keycloakXHR('protocol/openid-connect/token', {
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      username: username,
      password: password,
      grant_type: 'password'
    })
  },
  signOut (jwt) {
    return keycloakXHR('protocol/openid-connect/logout', {
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      refresh_token: jwt.refresh_token
    })
  },
  currentUser (jwt) {
    return keycloakXHR('protocol/openid-connect/userinfo', {
      access_token: jwt.access_token
    })
  },
  registrationURL () {
    return keycloakURL('protocol/openid-connect/registrations', {
      client_id: 'account',
      response_type: 'code',
      redirect_uri: keycloakURL('account')
    })
  },
  forgotPasswordURL () {
    return keycloakURL('protocol/openid-connect/forgot-credentials', {
      client_id: 'account',
      response_type: 'code',
      redirect_uri: keycloakURL('account')
    })
  },
  settingsURL () { return keycloakURL('account') }
}
