import React from 'react'
import './SignUpProgress.scss'

// This is the "timeline" shown at the top of the sign-up screen(s).
function SignUpProgress (props) {
  const steps = ['Account Creation', 'Subscription', 'Payment Details']

  const elems = steps.map((label, i) => (
    <div key={i} className={i < props.step ? 'done' : i === props.step ? 'current' : 'todo'}>
      <div className="stage">{i + 1}</div>
      <div className="label">{label}</div>
    </div>
  ))
  return <div className="sign-up-progress">{elems}</div>
}

export default SignUpProgress
