import React from 'react'
import './SimplePage.scss'
// Components:
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage'

// A simple page container, used for non-signed-in users.
function SimplePage (props) {
  return (
    <div className="simple-page">
      <div className="header">{props.header}</div>
      <div className={props.wide ? 'wide container' : 'container'}>
        <ErrorMessage text={props.error} />
        <div className="title">{props.title}</div>
        <div className="content">{props.children}</div>
      </div>
      <div className="footer">{props.footer}</div>
    </div>
  )
}

export default SimplePage
