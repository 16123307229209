import React from 'react'
import './CompatibilitySummary.scss'
// Components:
import CompatibilityLine from './CompatibilityLine'

// This is the "compatibility results" section on the right-hand side.
function CompatibilitySummary (props) {
  const list = props.items.map(
    i => <CompatibilityLine key={i.context} item={i} />
  )

  return (
    <div className="compatibility-summary">
      <div className="title">Checks Performed</div>
      <dl className="compatibility-list">{list}</dl>
    </div>
  )
}

export default CompatibilitySummary
