import React from 'react'
import './HazardousItemHead.scss'
// Components:
import HazardIconSet from '../HazardIconSet/HazardIconSet'
import LimitedToggle from '../LimitedToggle/LimitedToggle'
import RemoveButton from '../RemoveButton/RemoveButton'

// This represents a single item within the list of currently-added items.
function HazardousItemHead (props) {
  return (
    <div className="hazardous-item-head">
      <div className="item-head-main" onClick={props.onDropdownToggle}>
        <div className="item-head-text">
          {[props.hazard.un, props.hazard.imdg.psn].join(', ')}
        </div>
        <HazardIconSet hazard={props.hazard} />
      </div>
      <div className="item-head-buttons">
        <LimitedToggle
          enabled={props.hazard.limitedQuantity}
          onClick={props.onLimitedQuantityToggle}
        />
        <RemoveButton onClick={props.onRemove} />
      </div>
    </div>
  )
}

export default HazardousItemHead
