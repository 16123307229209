import React from 'react'
import './InvoiceInfo.scss'

import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePdf from './InvoicePdf'

const InvoiceInfo = ({ invoice }) => {
  return (
    <div className="invoice-info">
      <div className="logo"></div>
      <div className="text">
        <div>The Cotton Exchange Building, Cunard Two Suite,</div>
        <div>Bixteth Street, Liverpool L3 9LQ.</div>
        <div>sales@hazculator.co.uk</div>
        <div>0845 805 3762</div>
        <div>www.hazculator.co.uk</div>
      </div>
      <div className="text">
        <div>Dear {invoice.organisation},</div>
        <div>You have paid for the following this month:</div>
      </div>
      <div className="table">
        <div className="header">
          <div className="invoice-row">
            <div className="date">Date</div>
            <div className="value">Amount</div>
            <div className="licences">Licences</div>
            <div className="status">Status</div>
            <div className="payment-method">Payment Method</div>
          </div>
        </div>
        <div className="invoice-data">
          <div className="invoice-row">
            <div className="date">
              <span className="year">{invoice.date.year}</span>
              <span className="month">{invoice.date.month}</span>
              <span className="day">{invoice.date.day}</span>
            </div>
            <div className="value">
              {invoice.currency}
              {invoice.amountDue}
            </div>
            <div className="licences">
              {invoice.licenceCount}
            </div>
            <div className="status">
              {invoice.status}
            </div>
            <div className="payment-method">
              {invoice.paymentMethod}
            </div>
          </div>
        </div>
      </div>
      <div className="text">
        <div>Sincerely,</div>
        <div>HAZculator.</div>
      </div>
      <div className="text small">
        No commitment, your subscription continues on a monthly basis until cancelled. To avoid being charged, you must cancel your subscription at least a day before the renewal date.
      </div>
      <PDFDownloadLink
        className="simple-button"
        document={<InvoicePdf invoice={invoice}/>}
        fileName={`invoice-${invoice.id}.pdf`}>
        {({ blob, url, loading, error }) => 'Download PDF'}
      </PDFDownloadLink>
    </div>
  )
}

export default InvoiceInfo
