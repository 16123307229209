import React from 'react'
import './HazardousItemList.scss'
// Components:
import HazardousItem from '../HazardousItem/HazardousItem'

// This represents the list of currently-added items.
class HazardousItemList extends React.Component {
  hazardousItem = (item, idx) => (
    <HazardousItem
      key={[item.imdg.id, item.adr.id].join('-')}
      hazard={item}
      idx={idx}
      handleDropdownToggle={this.props.handleDropdownToggle}
      handleLimitedQuantityToggle={this.props.handleLimitedQuantityToggle}
      onRemove={e => this.props.onRemove(idx)}
    />
  )

  hazardousItemList = () => (
    this.props.items.map((item, idx) => this.hazardousItem(item, idx))
  )

  render () {
    return (
      <div className="hazardous-item-list">
        {this.hazardousItemList()}
      </div>
    )
  }
}

export default HazardousItemList
