import React from 'react'
import './PageWithNavbar.scss'
// Components:
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import SuccessMessage from '../SuccessMessage/SuccessMessage'

// A simple page container, used for signed-in users.
function PageWithNavbar (props) {
  return (
    <div className="page-with-navbar">
      <div className="navbar">{props.navbarItems}</div>
      <ErrorMessage text={props.error} />
      <SuccessMessage text={props.success} />
      <div className="content">{props.children}</div>
      <div className="footer">{props.footer}</div>
    </div>
  )
}

export default PageWithNavbar
