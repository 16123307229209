import React from 'react'
import './BillingHistory.scss'
// Components:
import PleaseWait from '../../../common/PleaseWait/PleaseWait'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimpleSection from '../../../common/SimpleSection/SimpleSection'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'
// Utilities:
import CostCalculator from '../../CostCalculator'
// PDFs:
import { PDFDownloadLink } from '@react-pdf/renderer'
import CompletePdf from './CompletePdf'

// Shows a list of invoices, fetched from Stripe.
class BillingHistory extends React.Component {
  constructor (props) {
    super(props)
    this.state = { data: null }
  }

  componentDidMount () {
    AccountManagement.subscriptionHistory(this.props.token).then(
      json => this.formatInvoices(json.data),
      this.props.onError
    )
  }

  upCase = word => word.charAt(0).toUpperCase() + word.slice(1)

  formatDate = dateCreated => {
    const date = new Date(dateCreated * 1000)
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
  }

  inferLicenceCount = invoice => {
    const seatsLine = invoice.lines.data.filter(l =>
      l.description.includes('Tier 2')
    )[0] || { quantity: 0 }
    return seatsLine.quantity
  }

  formatInvoices = invoices => {
    const items = invoices.map(invoice => {
      const paymentMethod = invoice.payment_intent.payment_method_types[0].split(' ').map(w => this.upCase(w)).join(' ')
      return ({
        date: this.formatDate(invoice.created),
        paymentMethod,
        id: invoice.id,
        amountDue: `${invoice.amount_due / 100}.00`,
        currency: CostCalculator.currency(invoice),
        licenceCount: this.inferLicenceCount(invoice),
        organisation: invoice.customer_name,
        status: this.upCase(invoice.status)
      })
    })
    this.setState({ data: items })
  }

  render () {
    if (!this.state.data) { return <PleaseWait /> }

    const items = this.state.data.map(invoice => {
      // const value = `${invoice.amount_due / 100}.00` // TODO: Assumes GBP...
      // const seats = invoice.lines.data.length - 1 // TODO: Verify this...

      return (
        <div key={invoice.id} className="invoice-row">
          <div className="date">
            <span className="year">{invoice.date.year}</span>
            <span className="month">{invoice.date.month}</span>
            <span className="day">{invoice.date.day}</span>
          </div>
          <div className="value">
            {invoice.currency}
            {invoice.amountDue}
          </div>
          <div className="seats">{invoice.licenceCount}</div>
          <button className="download-invoice" onClick={() => this.props.onSelect(invoice)}></button>
        </div>
      )
    })

    return (
      <SimpleSection title="Payment History">
        <div className="billing-history">
          <div className="header">
            <div className="invoice-row">
              <div className="date">Date</div>
              <div className="value">Amount</div>
              <div className="seats">Licences</div>
              <div className="download-invoice"></div>
            </div>
          </div>
          <div className="items">
            {items}
          </div>
          <div className="footer">
            <PDFDownloadLink className="simple-button expand" document={<CompletePdf items={this.state.data}/>} fileName="invoices.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
            </PDFDownloadLink>
          </div>
        </div>
        <SimpleButton
          onClick={this.props.onCancel}
          text="Back"
          expand={true}
          theme='cancel'
        />
      </SimpleSection>
    )
  }
}

export default BillingHistory
