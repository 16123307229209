// Calculate the cost of a (potential) subscription.
class CostCalculator {
  static totalCost (json, userCount, coupon) {
    const result = this.baseCost(json) + this.usersCost(json, userCount)

    // If a coupon is used, we subtract either a fixed value or a percentage:
    let discount = coupon ? coupon.discountValue / 100 : 0
    if (coupon && coupon.discountKind === 'percent') {
      discount = result * (coupon.discountValue / 100.0)
    }

    return result - discount
  }

  static baseCost (json) {
    return json.tiers[0].unit_amount / 100
  }

  // NOTE: Assumes that there are only two tiers, and that only tier 2 applies.
  static userCost (json) {
    return json.tiers[1].unit_amount / 100
  }

  static usersCost (json, userCount) {
    return this.userCost(json) * userCount
  }

  static currency (json) {
    return json.currency === 'gbp' ? '£' : json.currency
  }
}

export default CostCalculator
