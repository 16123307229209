/* eslint prefer-promise-reject-errors: 0 */

import React from 'react'

// This is basically just a wrapper around the standard Stripe "confirm" modal.
class StripeConfirm extends React.Component {
  componentDidMount () {
    const apiKey = process.env.REACT_APP_STRIPE_API_KEY

    const stripeResponse = new Promise((resolve, reject) => {
      window.Stripe(apiKey).handleCardPayment(this.props.clientSecret).then(
        sr => sr.error ? reject(sr.error.message) : resolve(sr.paymentIntent),
        () => reject('Unknown Stripe error')
      )
    })

    this.props.onComplete(stripeResponse)
  }

  render () { return 'Waiting for confirmation...' }
}

export default StripeConfirm
