import React from 'react'
import './AddLicences.scss'
// Components:
import FooterLinks from '../../FooterLinks/FooterLinks'
import SignUpProgress from '../SignUpProgress/SignUpProgress'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimplePage from '../../SimplePage/SimplePage'
import PleaseWait from '../../../common/PleaseWait/PleaseWait'
import EditPlan from '../../EditPlan/EditPlan'
import EditPlanSlider from '../../EditPlan/EditPlanSlider'

// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// Utilities
import CostCalculator from '../../CostCalculator'

// This now handles the second screen of the sign-up process.
class AddLicences extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      costs: null,
      licenceCount: 0
    }
  }

  componentDidMount () {
    AccountManagement.priceTiers().then(
      json => this.setState({ costs: json }),
      error => this.setState({ error: error })
    )
  }

  handleChange = (event) => {
    const value = (
      event.target.value === '' ? 0 : Number.parseInt(event.target.value)
    )
    return (value <= 100) && this.setState({ licenceCount: value })
  }

  handleSubmit = () => {
    this.setState({ loading: true })
    AccountManagement.updateLicences({ licenceCount: this.state.licenceCount }, this.props.token).then(
      json => this.props.onComplete(),
      error => this.setState({ error, loading: false })
    )
  }

  render () {
    if (!this.state.costs) { return <PleaseWait/> }

    return (
      <SimplePage
        error={this.state.error}
        title="Flexibility and control for your account"
        header={<SignUpProgress step={1} />}
        footer={<FooterLinks />}
        wide={true}
      >
        <div className="informational">Add additional licences to your subscription. You can cancel your subscription at any time.</div>
        <div className="costs-container">
          <div className="configure-licences">
            <EditPlan {...this.state} handleChange={this.handleChange} />
            <EditPlanSlider {...this.state} handleChange={this.handleChange} />
          </div>
          <div className="show-total">
            <div className="title">Total monthly cost</div>
            <div className="total">
              <span className="currency-symbol">{CostCalculator.currency(this.state.costs)}</span>
              <span className="cost">{CostCalculator.totalCost(this.state.costs, this.state.licenceCount)}</span>
              <span className="pennies">.00</span>
            </div>
            <div className="text-sm">No hidden costs</div>
          </div>
        </div>
        <div className="continue-to-payment">
          <SimpleButton
            disabled={this.state.loading}
            expand={true}
            onClick={this.handleSubmit}
            text="Continue to Payment"
          />
        </div>
        <SimpleButton
          disabled={this.state.loading}
          expand={true}
          onClick={this.props.onCancel}
          text="Return to Login"
          theme="muted"
        />
      </SimplePage>
    )
  }
}

export default AddLicences
