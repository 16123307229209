import React from 'react'
import './EditPlanSlider.scss'

const EditPlanSlider = (props) => (
  <React.Fragment>
    <input
      type="range"
      min="0"
      max="100"
      value={props.licenceCount}
      onChange={props.handleChange}
      className="licence-count-slider"
    />
    <span>0</span>
    <span className="scale-end">100</span>
  </React.Fragment>
)

export default EditPlanSlider
