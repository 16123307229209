import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image
} from '@react-pdf/renderer'

import logo from '../../../logo.png'

function InvoicePdf ({ invoice }) {
  const styles = StyleSheet.create({
    table: {
      display: 'table',
      width: 'auto',
      marginTop: '0',
      marginRight: '2cm',
      marginBottom: '1cm',
      marginLeft: '2cm',
      borderStyle: 'solid'
    },
    logo: {
      width: '3cm',
      marginLeft: '2cm',
      marginBottom: '1cm'
    },
    tableRow: {
      flexDirection: 'row',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderStyle: 'dotted',
      borderColor: '#999'
    },
    tableCol: {
      width: '3.2cm'
    },
    tableLastCol: {
      marginRight: '0',
      marginLeft: 'auto'
    },
    tableCell: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 12
    },
    tableHeader: {
      color: '#999',
      borderStyle: 'solid',
      borderWidth: 1
    },
    title: {
      marginTop: '2cm',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1cm'
    },
    contactInfo: {
      fontSize: 12,
      marginLeft: '2cm',
      marginBottom: '2cm'
    },
    text: {
      fontSize: 12,
      marginLeft: '2cm',
      marginBottom: '1cm'
    }
  })

  return (
    <Document>
      <Page>
        <Text style={styles.title}>Receipt</Text>
        <Image src={logo} style={styles.logo} />
        <View style={styles.contactInfo}>
          <Text>The Cotton Exchange Building, Cunard Two Suite,</Text>
          <Text>Bixteth Street, Liverpool L3 9LQ.</Text>
          <Text>sales@hazculator.co.uk</Text>
          <Text>0845 805 3762</Text>
          <Text>www.hazculator.co.uk</Text>
        </View>
        <View style={styles.text}>
          <Text>{`Dear ${invoice.organisation},`}</Text>
          <Text>You have paid for the following this month:</Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Licences</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Status</Text>
            </View>
            <View style={[styles.tableCol, styles.tableLastCol]}>
              <Text style={styles.tableCell}>Payment Method</Text>
            </View>
          </View>
          <View style={styles.tableRow} key={invoice.id}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {`${invoice.date.day}/${invoice.date.month}/${invoice.date.year}`}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {invoice.currency}
                {invoice.amountDue}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {invoice.licenceCount}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {invoice.status}
              </Text>
            </View>
            <View style={styles.tableLastCol}>
              <Text style={styles.tableCell}>
                {invoice.paymentMethod}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.text}>
          <Text>Sincerely,</Text>
          <Text>HAZculator.</Text>
        </View>
      </Page>
    </Document>
  )
}

export default InvoicePdf
