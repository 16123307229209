import React from 'react'
import './CompatibilityLine.scss'
// Components:
import HazardIcon from '../../HazardIcon/HazardIcon'

// This is the "compatibility results" section on the right-hand side.
function CompatibilityLine (props) {
  if (props.item.context === 'ADR exemptions due to 1.1.3.6') { return null }

  const title = props.item.compatible ? 'Passed' : 'Failed'
  const severity = props.item.compatible ? 'low' : 'high'

  return (
    <React.Fragment>
      <dt className="text">{props.item.context}</dt>
      <dd>
        <HazardIcon
          title={title}
          severity={severity}
          theme={'limit'} />
      </dd>
    </React.Fragment>
  )
}

export default CompatibilityLine
