import React from 'react'
import './PromoCodeSearch.scss'

// APIs:
import AccountManagement from '../../apis/AccountManagement'

// This component allows a user to enter a code, and then check if it's valid.
class PromoCodeSearch extends React.Component {
  constructor (props) {
    super(props)

    this.state = { value: '' }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value }) // Don't `toUpperCase()` here!
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const code = this.state.value.toUpperCase()
    this.setState({ value: '' })

    this.props.onSubmit(code, AccountManagement.searchCoupon(code))
  }

  render () {
    const disabled = !this.props.onSubmit

    return (
      <form className='promo-code-search' onSubmit={this.handleSubmit}>
        <input
          className='input'
          disabled={disabled}
          value={this.state.value}
          onChange={this.handleChange}
        />
        <button className='apply-promo-code' disabled={disabled}>
          Apply
        </button>
      </form>
    )
  }
}

export default PromoCodeSearch
