import React from 'react'
import './CompatibilityContextList.scss'
// Components:
import CompatibilityContext from './CompatibilityContext'

// This is the "compatibility results" section on the right-hand side.
function CompatibilityContextList (props) {
  const list = props.contexts.map(context => (
    <CompatibilityContext key={context} context={context} />
  ))

  return <div className='compatibility-context-list'>{list}</div>
}

export default CompatibilityContextList
