import React from 'react'
import './HazardIcon.scss'

// A hazard icon.
function HazardIcon (props) {
  const classes = ['hazard-icon', props.severity, props.theme].filter(Boolean).join(' ')
  return <div className={classes} title={props.details}>{props.title}</div>
}

export default HazardIcon
