import React from 'react'

// Components:
import CostBreakdown from '../../CostBreakdown/CostBreakdown'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimpleRow from '../../../common/SimpleRow/SimpleRow'
import SimpleSection from '../../../common/SimpleSection/SimpleSection'

// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// This is basically just an augmented version of <CostBreakdown>, for coupons.
class BillingStatus extends React.Component {
  constructor (props) {
    super(props)

    this.state = { coupon: null }
  }

  // Unlike sign-up, we already know which (if any) coupon applies - fetch it:
  componentDidMount () {
    AccountManagement.activeCoupon(this.props.token).then(
      json => this.setState({ coupon: json.coupon }),
      error => this.props.onError(error)
    )
  }

  render () {
    return (
      <SimpleSection>
        <CostBreakdown
          token={this.props.token}
          coupon={this.state.coupon}
          totalLabel='Due this month'
          licenceCount={this.props.licenceCount}
          onError={this.props.onError}
        />
        <small>
          The amount shown above will be charged at the start of the next
          billing period to your stored payment method. All subscriptions
          are subject to our <a target='_blank' href='/static/terms.html'>
          terms and conditions</a>.
        </small>
        <SimpleRow>
          <div className='licence-links'>
            <SimpleButton
              text='View payment history'
              theme='muted'
              onClick={this.props.onHistory}
            />
            <SimpleButton
              text='Edit licence plan'
              disabled={!this.props.onEdit}
              onClick={this.props.onEdit}
            />
          </div>
        </SimpleRow>
      </SimpleSection>
    )
  }
}

export default BillingStatus
