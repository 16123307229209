import React from 'react'
// Components:
import HazculatorForm from '../HazculatorForm/HazculatorForm'
import LogoutButton from '../../account/LogoutButton/LogoutButton'
import PageWithNavbar from '../../common/PageWithNavbar/PageWithNavbar'
import VersionForm from '../VersionForm/VersionForm'

// This represents the HAZculator page as a whole.
class HazculatorPage extends React.Component {
  state = { version: null }

  handleVersionChange = (version) => {
    this.setState({ version })
  }

  navbarItems = () => (
    <React.Fragment>
      <VersionForm
        token={this.props.token}
        version={this.state.version}
        onChange={this.handleVersionChange}
        onError={this.props.onError}
      />
      <a className="settings-link" href="#top" onClick={this.props.onSettings}>
        Settings
      </a>
      <LogoutButton onClick={this.props.onSignOut} />
    </React.Fragment>
  )

  render () {
    return (
      <PageWithNavbar error={this.props.error} navbarItems={this.navbarItems()}>
        <HazculatorForm
          onError={this.props.onError}
          version={this.state.version}
          token={this.props.token}
        />
      </PageWithNavbar>
    )
  }
}

export default HazculatorPage
