import React from 'react'
import './SimpleButton.scss'

// A standard button.
function SimpleButton (props) {
  const off = props.disabled

  let css = ['simple-button']
  if (props.expand) { css.push('expand') }
  if (props.theme) { css.push(props.theme) }

  return (
    <button className={css.join(' ')} onClick={props.onClick} disabled={off}>
      {props.text}
    </button>
  )
}

export default SimpleButton
