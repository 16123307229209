import React from 'react'
import './DataList.scss'

// This is just a basic HTML `<dl>` component.
function DataList (props) {
  const items = Object.keys(props.data).map(
    (key) => (
      <React.Fragment key={key}>
        <dt>{key}</dt>
        <dd>{props.data[key]}</dd>
      </React.Fragment>
    )
  )

  return <dl className="data-list">{items}</dl>
}

export default DataList
