import React from 'react'
import './UserList.scss'
// Components:
import SimpleSection from '../../../common/SimpleSection/SimpleSection'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import PleaseWait from '../../../common/PleaseWait/PleaseWait'
import UserForm from '../UserForm/UserForm'
import UserInfo from '../UserInfo/UserInfo'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// This is just a list of users.
class UserList extends React.Component {
  constructor (props) {
    super(props)

    this.state = { users: [], user: null, mode: 'loading' }
  }

  componentDidMount () {
    this.getOtherUsers()
  }

  getOtherUsers = () => {
    this.setState({ mode: 'loading' })

    AccountManagement.otherUsers(this.props.token).then(
      json => this.setState({ mode: 'list', users: json.users }),
      this.props.onError
    )
  }

  // Called when a user is created, updated, or deleted.
  handleChange = (json) => {
    let mode = 'list'
    if ('warnings' in json) {
      mode = this.checkWhitelistedWarning(json.warnings) ? 'list' : 'edit'
    }
    this.setState({ mode, user: null, users: json.users })
    this.clearMessages()
  }

  // Called when the Create/Update User form is closed.
  handleCancel = () => {
    this.setState({ mode: 'list', user: null })
  }

  handleCreate = () => {
    this.setState({ mode: 'new', user: {} })
  }

  handleUpdate = (user) => {
    this.setState({ mode: 'edit', user })
  }

  handleDelete = (user) => {
    this.setState({ mode: 'delete', user })
  }

  clearMessages = () => {
    this.props.onError(null)
    this.props.successMessage(null)
  }

  deleteUser = () => {
    this.setState({ mode: 'loading' })

    AccountManagement.deleteUser(this.state.user.id, this.props.token).then(
      json => {
        this.handleChange(json)
        this.props.successMessage('User has been successfully deleted.')
      },
      error => {
        this.props.onError(error)
        this.props.successMessage(null)
        this.setState({ mode: 'delete' })
      }
    )
  }

  whitelist = ['Failed to send email to']

  checkWhitelistedWarning = (warnings) => {
    let whitelisted = true
    warnings.forEach(w => (
      this.whitelist.forEach(wl => (
        whitelisted = whitelisted && w.includes(wl)
      ))
    ))
    return whitelisted
  }

  users = () => this.state.users.map(user => this.userInfo(user))

  userForm = () => (
    <UserForm
      user={this.state.user}
      token={this.props.token}
      onSuccess={this.handleChange}
      successMessage={this.props.successMessage}
      clearMessages={this.clearMessages}
      onCancel={this.handleCancel}
      onError={this.props.onError}
      handleDelete={this.handleDelete}
    />
  )

  userList = () => (
    <div className="user-list">
      <div className="users">
        {this.users()}
      </div>
      <SimpleButton
        expand={true}
        text="Add new user"
        onClick={this.handleCreate}
        disabled={this.availableLicences() === 0}
      />
    </div>
  )

  userDelete = () => (
    <React.Fragment>
      <span>Are you sure you want to delete <strong>{this.state.user.email}?</strong></span>
      <div className="delete-options">
        <SimpleButton
          expand={true}
          theme="cancel"
          text="Cancel"
          onClick={this.handleCancel} />
        <SimpleButton
          expand={true}
          theme="warning"
          text="Delete"
          onClick={this.deleteUser} />
      </div>
    </React.Fragment>
  )

  userInfo = (user) => (
    <UserInfo
      key={user.id}
      user={user}
      onUpdate={this.handleUpdate}
      onDelete={this.handleDelete}
    />
  )

  currentTitle = () => {
    switch (this.state.mode) {
      case 'list': return 'Additional Users'
      case 'new': return 'New User'
      case 'edit': return 'Edit user details'
      default: return null
    }
  }

  availableLicences = () => Math.max(0, this.props.licenceCount - this.state.users.length)

  licenceCountIndicator = () => {
    const count = this.availableLicences()
    return count === 0 ? 'No available licences' : `Available licences: ${count} / ${this.props.licenceCount}`
  }

  licenceCountClass = () => {
    const count = this.availableLicences()
    return count === 0 ? 'no-licences licences' : 'licences-available licences'
  }

  loading = () => (
    <PleaseWait />
  )

  render () {
    return (
      <SimpleSection title={this.currentTitle()}>
        {this.state.mode === 'list' && (
          <div className={this.licenceCountClass()}>
            {this.licenceCountIndicator()}
          </div>
        )}
        {(() => {
          switch (this.state.mode) {
            case 'loading': return this.loading()
            case 'list': return this.userList()
            case 'edit': return this.userForm()
            case 'new': return this.userForm()
            case 'delete': return this.userDelete()
            default: return null
          }
        })()}
      </SimpleSection>
    )
  }
}

export default UserList
