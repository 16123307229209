import React from 'react'
import './UserInfo.scss'

const Options = props => {
  const deleteButton = () => (
    <button className="delete" onClick={e => props.onDelete(props.user)}>Delete user</button>
  )

  return (
    <div className={`actions ${props.showMenu ? 'visible' : 'hidden'}`} >
      <div className="options" onClick={props.toggleMenu}>···</div>
      <div className="inner-actions">
        <button className="update" onClick={e => props.onUpdate(props.user)}>
          Edit details
        </button>
        {deleteButton()}
      </div>
    </div>
  )
}

// Represents a single user on the Settings screen.
class UserInfo extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showMenu: false }
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }))
  }

  closeMenu = () => {
    this.setState({ showMenu: false })
  }

  name = [this.props.user.firstName, this.props.user.lastName].filter(s => s)
  avatar = this.name.map(s => s.charAt()).join('') || '?'

  render () {
    return (
      <div className="user-info">
        <div className="avatar">{this.avatar}</div>
        <div className="name">{this.name.join(' ') || this.props.user.email}</div>
        <div className="email">{this.props.user.email}</div>
        <Options
          {...this.props}
          showMenu={this.state.showMenu}
          onBlur={this.closeMenu}
          toggleMenu={this.toggleMenu}
        />
      </div>
    )
  }
}

export default UserInfo
