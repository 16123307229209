import React from 'react'
import './VersionForm.scss'
// APIs:
import HAZculator from '../../apis/HAZculator'

// This represents the "[] 2017 regulations   [] 2019 regulations" selector.
class VersionForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = { versions: null }
  }

  componentDidMount () {
    HAZculator.versions(this.props.token).then(
      json => {
        this.setState({ versions: json })
        this.props.onChange(json[json.length - 1].id)
      },
      error => this.props.onError(error)
    )
  }

  render () {
    if (!this.state.versions) { return null }

    // NOTE: We only display the two most-recent versions:
    const items = this.state.versions.slice(-2).map(v => {
      if (this.props.version === v.id) {
        return <div key={v.id} className="on">{v.text}</div>
      }

      const func = () => this.props.onChange(v.id)
      const aria = ['Switch to', v.text].join(' ')

      return (
        <button key={v.id} className="off" onClick={func} aria-label={aria}>
          {v.text}
        </button>
      )
    })

    return <div className="version-form">{items}</div>
  }
}

export default VersionForm
