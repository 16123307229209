import React from 'react'
import './SimpleDropdown.scss'

// A standard drop-down.
function SimpleDropdown (props) {
  const items = props.options.map(
    (i) => <option key={i.text} value={i.id}>{i.text}</option>
  )

  const handler = (event) => props.onChange(event.target.value)

  // Note that 0 is a valid value, so don't default to '' in that case:
  const v = props.value === undefined || props.value === null ? '' : props.value

  return (
    <label className="simple-dropdown">
      <span className="label">{props.label}</span>
      <select
        value={v}
        onChange={handler}
        disabled={props.options.length === 0}
        required
      >
        <option key="blank" value="" disabled>{props.placeholder}</option>
        {items}
      </select>
    </label>
  )
}

export default SimpleDropdown
