import React from 'react'
import './FooterLinks.scss'

// A simple page container, used for non-signed-in users.
function FooterLinks (props) {
  return (
    <div className="footer-links">
      <a target="_blank" href="/static/terms.html">Terms of Service</a>
      -
      <a target="_blank" href="/static/privacy.html">Privacy Policy</a>
    </div>
  )
}

export default FooterLinks
