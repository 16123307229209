import React from 'react'
// Components:
import DataList from '../../common/DataList/DataList'
import GenericSection from '../../common/GenericSection/GenericSection'
// Utilities:
import PrettyPrint from './PrettyPrint'

// This represents a single item within the list of currently-added items.
class HazardousItemImdg extends React.Component {
  state = { expanded: false }

  handleDropdownToggle = () => (
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  )

  data = () => {
    let { imdg } = this.props

    return (
      {
        'PSN': imdg.psn,
        'Classes': PrettyPrint.classes(imdg),
        'Packing Group': PrettyPrint.packingGroup(imdg),
        'Flashpoint': PrettyPrint.flashpoint(imdg),
        'Marine Pollutant': imdg.marinePollutant,
        'Limited Quantity': PrettyPrint.limitedQuantity(imdg),
        'Excepted Quantity': PrettyPrint.exceptedQuantity(imdg),
        'Special Provisions': PrettyPrint.specialProvisions(imdg),
        'Stowage Category': PrettyPrint.stowageCategory(imdg),
        'Segregation Groups': PrettyPrint.segregationGroups(imdg),
        'Segregation Instructions': PrettyPrint.segregationInstructions(imdg),
        'Foodstuffs Segregation': PrettyPrint.foodstuffs(imdg),
        'Treat as class': PrettyPrint.treatAsClass(imdg)
      }
    )
  }

  text = [
    this.props.imdg.revision,
    PrettyPrint.imdgDescription(this.props.un, this.props.imdg)
  ].join(': ')

  head = () => (
    <div className="imdg-head">
      {this.text}
    </div>
  )

  render () {
    return (
      <GenericSection
        head={this.head()}
        body={<DataList data={this.data()} />}
        expanded={this.state.expanded}
        onDropdownToggle={this.handleDropdownToggle}
      />
    )
  }
}

export default HazardousItemImdg
