import React from 'react'
import './CompatibilityResult.scss'
// Components:
import CompatibilitySummary from './Summary/CompatibilitySummary'
import CompatibilityDetails from './Details/CompatibilityDetails'
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'
import HazardIcon from '../HazardIcon/HazardIcon'
// APIs:
import HAZculator from '../../apis/HAZculator'

// This is the "compatibility results" section on the right-hand side.
class CompatibilityResult extends React.Component {
  state = {
    summary: JSON.parse(sessionStorage.getItem('summary')) || null,
    details: JSON.parse(sessionStorage.getItem('details')) || null
  }

  componentDidUpdate (prevProps) {
    if (this.props.items === prevProps.items) {
      return
    } else if (this.props.items.length < 2) {
      return (
        this.setState({ summary: null, details: null }),
        sessionStorage.removeItem('summary', 'details')
      )
    }
    this.compatibilityCheck()
  }

  compatibilityCheck = () => (
    HAZculator.compatibility(this.props.token, this.items()).then(
      json => {
        this.setState({ summary: json.summary, details: json.details })
        this.setStorage('summary', json.summary)
        this.setStorage('details', json.details)
      },
      error => this.props.onError(error)
    )
  )

  setStorage = (k, v) => (
    sessionStorage.setItem(k, JSON.stringify(v))
  )

  handleDropdownToggle = (idx) => {
    let details = this.state.details.slice()
    details[idx] = { ...details[idx], expanded: !details[idx].expanded }
    this.setState({ details })
    sessionStorage.setItem('details', JSON.stringify(details))
  }

  item = (dg, idx) => ({
    imdg: dg.imdg.id,
    adr: dg.adr.id,
    limitedQuantity: dg.limitedQuantity
  })

  items = () => this.props.items.map(dg => this.item(dg))

  summaryIcon = (text) => {
    const checks = this.state.summary
    if (checks.some(i => i.context.startsWith(text) && !i.compatible)) {
      return { text: 'NOT COMPATIBLE', level: 'high' }
    } else {
      return { text: 'No compatibility issues found', level: 'low' }
    }
  }

  render () {
    if (!this.state.summary) { return null }

    const adr = this.summaryIcon('ADR')
    const imdg = this.summaryIcon('IMDG')

    return (
      <div className="compatibility-result">
        <div className="title">Compatibility</div>
        <div className="icons">
          <HazardIcon title="ADR" details={adr.text} severity={adr.level} />
          <HazardIcon title="IMDG" details={imdg.text} severity={imdg.level} />
        </div>
        <ErrorBoundary>
          <CompatibilitySummary items={this.state.summary} />
        </ErrorBoundary>
        <ErrorBoundary>
          <CompatibilityDetails
            items={this.state.details}
            handleDropdownToggle={this.handleDropdownToggle}
          />
        </ErrorBoundary>
      </div>
    )
  }
}

export default CompatibilityResult
