import React from 'react'
import './RemoveButton.scss'

// A standard button.
function RemoveButton (props) {
  return (
    <button className="remove-button" onClick={props.onClick}>Remove</button>
  )
}

export default RemoveButton
