import React from 'react'
import './BillingEditor.scss'

// Components:
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimpleSection from '../../../common/SimpleSection/SimpleSection'

// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// Utilities
import CostCalculator from '../../CostCalculator'
import CouponHelper from '../../CouponHelper'

// This allows the number of 'additional seats' to be changed after sign-up.
class BillingEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      newLicenceCount: this.props.licenceCount,
      costs: null,
      coupon: null
    }
  }

  componentDidMount () {
    AccountManagement.priceTiers(this.props.token).then(
      json => this.setState({ costs: json }),
      this.props.onError
    )
    AccountManagement.activeCoupon(this.props.token).then(
      json => this.setState({ coupon: json.coupon }),
      this.props.onError
    )
  }

  handleChange = (event) => {
    const value = Number(event.target.value || '0')
    if (value <= 100) { this.setState({ newLicenceCount: value }) }
  }

  handleSubmit = (event) => {
    this.props.onSubmit(this.state.newLicenceCount)
  }

  render () {
    if (!this.state.costs) { return null }

    const currency = CostCalculator.currency(this.state.costs)
    const baseCost = CostCalculator.baseCost(this.state.costs)
    const userCost = CostCalculator.userCost(this.state.costs)

    const oldTotal = CostCalculator.totalCost(
      this.state.costs,
      this.props.licenceCount,
      this.state.coupon
    )

    const newTotal = CostCalculator.totalCost(
      this.state.costs,
      this.state.newLicenceCount,
      this.state.coupon
    )

    return (
      <SimpleSection title='Update licence plan'>
        <div className='billing-editor'>
          {this.state.coupon ? (
            <div className='coupon'>
              <div className='coupon-name'>
                {this.state.coupon.name}
              </div>
              <div className='coupon-discount'>
                {CouponHelper.discountText(this.state.coupon)}
              </div>
            </div>
          ) : null}
          <div className='admin-cost-row'>
            <div className='admin-label'>Software & Admin Licence</div>
            <div>{currency}{baseCost.toFixed(2)}</div>
          </div>
          <div className='licence-count-row'>
            <div className='licence-count-label'>
              Number of licences
              <span>
                {currency}{userCost.toFixed(2)}
                <span className='per-licence-text'> per licence</span>
              </span>
            </div>
            <input
              type='number'
              min={0}
              max={100}
              value={this.state.newLicenceCount.toString()}
              onChange={this.handleChange}
              className='licence-count-box'
            />
          </div>
          <div className='price-comparison-row'>
            <div className='current-price'>
              <div className='current-price-label'>Current Price:</div>
              <div className='current-price-amount'>
                {currency}{oldTotal.toFixed(2)}
              </div>
            </div>
            <div className='new-price'>
              <div className='new-price-label'>New Price:</div>
              <div className='new-price-amount'>
                {currency}{newTotal.toFixed(2)}
              </div>
            </div>
          </div>
          <small>
            The amount shown above will be charged at the start of the next
            billing period to your stored payment method. All subscriptions
            are subject to our <a target='_blank' href='/static/terms.html'>
            terms and conditions</a>.
          </small>
          <SimpleButton
            onClick={this.handleSubmit}
            text='Update licence'
            expand={true}
          />
        </div>
      </SimpleSection>
    )
  }
}

export default BillingEditor
