import React from 'react'
// import './StripeContainer.scss'
// Components:
import PleaseWait from '../../common/PleaseWait/PleaseWait'
import StripeConfirm from './StripeConfirm'
import StripePayment from './StripePayment'
// APIs:
import AccountManagement from '../../apis/AccountManagement'

// This handles the various stages of a Stripe payment.
class StripeContainer extends React.Component {
  constructor (props) {
    super()

    this.state = { status: 'loading' }

    this.message = {
      create: 'Please enter your payment details so that we can create your subscription.',
      invalid: 'Your payment could not be processed - please provide an alternative payment method.',
      replace: 'If you would like to use a different payment method for future payments, please provide details below.',
      succeeded: 'Payment method has been updated.'
    }
  }

  componentDidMount () {
    this.fetchSubscription()
  }

  fetchSubscription = () => (
    AccountManagement.fetchSubscription(this.props.token).then(
      this.firstSuccess,
      this.showFailure
    )
  )

  firstSuccess = (json) => {
    const { status, action } = json
    this.setState({ status, action })
  }

  showSuccess = (json) => {
    const { status, action } = json
    this.setState({ status, action })
    this.props.onSuccess(json)
    this.props.successMessage(this.message[status])
    this.props.onError(null)
  }

  showFailure = (error) => {
    this.props.onError(error)
    this.setState({ status: null })
  }

  handleSignUp = (stripeResponse) => {
    stripeResponse.then(
      this.signUp,
      this.showFailure
    )
  }

  signUp = (stripeToken) => {
    this.setState({ status: 'loading' })

    AccountManagement.createSubscription(
      stripeToken,
      this.props.token,
      this.props.promoCode
    ).then(this.showSuccess, this.showFailure)
  }

  handleChangeCard = (stripeResponse) => {
    stripeResponse.then(
      this.changeCard,
      this.showFailure
    )
  }

  changeCard = (stripeToken) => {
    this.setState({ status: 'loading' })
    AccountManagement.updateSubscription(stripeToken, this.props.token).then(
      this.showSuccess,
      this.showFailure
    )
  }

  handleConfirm = (stripeResponse) => {
    stripeResponse.then(
      json => {
        this.showSuccess({ status: 'succeeded' })
      },
      error => {
        this.setState({ status: 'requires_payment_method' })
        this.showFailure(error)
      }
    )
  }

  stripePaymentSuccess = () => (
    <StripePayment
      reason={this.message.replace}
      forcePromoCode={!!this.props.onPromoCodeChange}
      onSubmit={p => this.handleChangeCard(p)}
    >
      {this.props.children}
    </StripePayment>
  )

  stripeConfirm = () => (
    <StripeConfirm
      clientSecret={this.state.action}
      onComplete={p => this.handleConfirm(p)}
    />
  )

  stripePaymentInvalid = () => (
    <StripePayment
      reason={this.message.invalid}
      forcePromoCode={!!this.props.onPromoCodeChange}
      onSubmit={p => this.handleChangeCard(p)}
    >
      {this.props.children}
    </StripePayment>
  )

  stripePaymentCreate = () => (
    <StripePayment
      reason={this.message.create}
      onPromoCode={this.props.onPromoCodeChange}
      onSubmit={p => this.handleSignUp(p)}
      buttonText="Pay Now"
    >
      {this.props.children}
    </StripePayment>
  )

  render () {
    switch (this.state.status) {
      case 'loading': return <PleaseWait />
        // If the user has an active subscription, let them change/cancel it:
      case 'succeeded': return this.stripePaymentSuccess()
        // If the user has tried to pay, but needs to do something, prompt them:
      case 'requires_action': return this.stripeConfirm()
        // If they tried to pay, but their card was declined, let them change it:
      case 'requires_payment_method': return this.stripePaymentInvalid()
        // If none of the above apply, they haven't signed up yet - so let them:
      default: return this.stripePaymentCreate()
    }
  }
}

export default StripeContainer
