import React from 'react'
import './LoginForm.scss'
// Components:
import SimplePage from '../SimplePage/SimplePage'
import FooterLinks from '../FooterLinks/FooterLinks'
import LabelInput from '../../common/LabelInput/LabelInput'
import SimpleButton from '../../common/SimpleButton/SimpleButton'

// This handles everything related to sign-in, except the actual KeyCloak bit.
class LoginForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    sessionStorage.clear()
    this.props.onSubmit(this.state.email, this.state.password, 'hazculator')
  }

  loginForm = () => (
    <div className="login-form">
      <SimplePage
        error={this.props.error}
        title="Log in to HAZculator"
        footer={<FooterLinks />}
      >
        <form onSubmit={this.handleSubmit}>
          <LabelInput
            label="Email Address"
            type="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
          <LabelInput
            label="Password"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
          <div className="disclaimer">
            I have read and agree to
            the <a target="_blank" href="/static/terms.html">Terms and
            Conditions</a>.
            <input type="checkbox" required />
          </div>
          <SimpleButton
            text="Login"
            expand={true}
          />
        </form>
        <div className="forgot-password">
          <button onClick={this.props.onForgot}>Forgot your password?</button>
        </div>
        <div className="create-account">
          New user? <button onClick={this.props.onCreate}>Register</button>
        </div>
        <div className="get-help">
          If you need any help or have any questions regarding this product,
          please do not hesitate to contact us on
          <a href="mailto:support@hazculator.co.uk"> support@hazculator.co.uk</a>
        </div>
      </SimplePage>
    </div>
  )

  render () {
    return <this.loginForm />
  }
}

export default LoginForm
