import React from 'react'
import './SearchForm.scss'
// Components:
import SimpleButton from '../../common/SimpleButton/SimpleButton'
import SimpleDropdown from '../../common/SimpleDropdown/SimpleDropdown'
import UnNumberDropdown from '../UnNumberDropdown/UnNumberDropdown'
// APIs:
import HAZculator from '../../apis/HAZculator'

// This is the top "search" bar, where the user selects UN/IMDG/ADR values.
class SearchForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      unNumber: null,
      selectedPackingGroup: null,
      selectedImdg: null,
      selectedAdr: null,
      packingGroups: [],
      imdgOptions: [],
      adrOptions: []
    }
  }

  // Used when the UN or Packing Group changes to auto-select the first item:
  firstOrNull = (data) => data.length === 1 ? data[0].id : null

  // NOTE: Some UNs have a `null` packing group for JUST the IMDG or ADR reg...
  pgFilter = (regs, pg) => {
    return pg ? regs.filter(r => !r.packingGroup || r.packingGroup === pg) : []
  }

  // We have to be careful here, since odd cases like UN3316 complicate things:
  packingGroupsFrom = (json) => {
    // Look at ADR regulations by default, to handle UN1374 and UN1512:
    let regs = json.adrRegulations
    // If no ADR packing groups are found, fall back to IMDG (for UN3255 et al)
    if (regs.every(reg => !reg.packingGroup)) { regs = json.imdgRegulations }
    // Ensure that we don't have any duplicate packing group values:
    const groups = Array.from(new Set(regs.map(i => i.packingGroup)))
    // Convert the result into the format used by SimpleDropdown for IMDG/ADR:
    return groups.map(i => ({ id: i || 'x', text: i || 'None' }))
  }

  handleUnNumberChange = (value) => {
    this.setState({
      unNumber: Number(value),
      selectedPackingGroup: null,
      selectedImdg: null,
      selectedAdr: null,
      packingGroups: [],
      imdgOptions: [],
      adrOptions: []
    })

    if (!value) { return }

    HAZculator.variants(this.props.token, this.props.version, value).then(
      json => {
        const packingGroups = this.packingGroupsFrom(json)
        this.setState({
          selectedPackingGroup: this.firstOrNull(packingGroups),
          selectedImdg: this.firstOrNull(json.imdgRegulations),
          selectedAdr: this.firstOrNull(json.adrRegulations),
          packingGroups: packingGroups,
          imdgOptions: json.imdgRegulations,
          adrOptions: json.adrRegulations
        })
      },
      error => this.props.onError(error)
    )
  }

  handlePackingGroupChange = (value) => {
    const imdg = this.pgFilter(this.state.imdgOptions, value)
    const adr = this.pgFilter(this.state.adrOptions, value)

    this.setState({
      selectedPackingGroup: value,
      selectedImdg: this.firstOrNull(imdg),
      selectedAdr: this.firstOrNull(adr)
    })
  }

  handleImdgChange = (value) => this.setState({ selectedImdg: Number(value) })
  handleAdrChange = (value) => this.setState({ selectedAdr: Number(value) })

  handleAdd = (event) => {
    this.props.onAdd(this.state.selectedImdg, this.state.selectedAdr)
    this.handleUnNumberChange(null)
  }

  handleReset = () => {
    this.props.onReset()
    this.handleUnNumberChange(null)
  }

  render () {
    const { selectedPackingGroup } = this.state

    return (
      <React.Fragment>
        <div className="search-form">
          <UnNumberDropdown
            token={this.props.token}
            version={this.props.version}
            value={this.state.unNumber}
            onChange={this.handleUnNumberChange}
            onError={this.props.onError}
          />
          <SimpleDropdown
            label="Packing Group"
            placeholder="Packing group: please select"
            value={selectedPackingGroup}
            options={this.state.packingGroups}
            onChange={this.handlePackingGroupChange}
          />
          <SimpleDropdown
            label="IMDG"
            placeholder="IMDG reg: please select"
            value={this.state.selectedImdg}
            options={this.pgFilter(this.state.imdgOptions, selectedPackingGroup)}
            onChange={this.handleImdgChange}
          />
          <SimpleDropdown
            label="ADR"
            placeholder="ADR reg: please select"
            value={this.state.selectedAdr}
            options={this.pgFilter(this.state.adrOptions, selectedPackingGroup)}
            onChange={this.handleAdrChange}
          />
          <SimpleButton
            text="Add"
            onClick={this.handleAdd}
            disabled={!this.state.selectedImdg || !this.state.selectedAdr}
          />
          <SimpleButton
            text="Reset"
            onClick={this.handleReset}
            disabled={this.props.disableReset}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default SearchForm
