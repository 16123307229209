import React from 'react'
// Components:
import GenericSection from '../../common/GenericSection/GenericSection'
import HazardousItemHead from './HazardousItemHead'
import HazardousItemBody from './HazardousItemBody'

// This represents a single item within the list of currently-added items.
class HazardousItem extends React.Component {
  handleDropdownToggle = () => (
    this.props.handleDropdownToggle(this.props.idx)
  )

  handleLimitedQuantityToggle = () => (
    this.props.handleLimitedQuantityToggle(this.props.idx)
  )

  head = () => (
    <HazardousItemHead
      onDropdownToggle={this.handleDropdownToggle}
      onLimitedQuantityToggle={this.handleLimitedQuantityToggle}
      {...this.props}
    />
  )

  body = () => (
    <HazardousItemBody {...this.props.hazard} />
  )

  render () {
    return (
      <GenericSection
        head={this.head()}
        body={this.body()}
        expanded={this.props.hazard.expanded}
        handleClick={this.handleDropdownToggle}
      />
    )
  }
}

export default HazardousItem
