import React from 'react'
import './ChangePasswordPage.scss'
// Components:
import SimplePage from '../../SimplePage/SimplePage'
import PasswordForm from '../PasswordForm/PasswordForm'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// Allows a user to request a password reset.
class ChangePasswordPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleSubmit = (password) => {
    AccountManagement.resetPassword(this.props.resetToken, password).then(
      this.props.onSuccess,
      this.handleError
    )
  }

  handleError = (error) => {
    this.setState({ error: error })
  }

  render () {
    return (
      <div className="change-password-page">
        <SimplePage error={this.state.error} title="Change password">
          <PasswordForm
            onSubmit={this.handleSubmit}
            onError={this.handleError}
          />
        </SimplePage>
      </div>
    )
  }
}

export default ChangePasswordPage
