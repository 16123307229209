import React from 'react'
import './PasswordForm.scss'
// Components:
import SimpleRow from '../../../common/SimpleRow/SimpleRow'
import LabelInput from '../../../common/LabelInput/LabelInput'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'

const DEFAULT_BUTTON_TEXT = 'Update Details'

// This is used in various places where a user can change their password.
class PasswordForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      confirm: ''
    }
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  handleConfirmChange = (event) => {
    this.setState({ confirm: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.state.password === this.state.confirm) {
      this.props.onSubmit(this.state.password)
    } else {
      this.props.onError('Password confirmation does not match')
    }
  }

  render () {
    const { buttonText } = this.props

    return (
      <form className="password-form" onSubmit={this.handleSubmit}>
        <div className="additional-fields">
          {this.props.children}
        </div>
        <SimpleRow>
          <LabelInput
            label="New Password"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            disabled={this.props.disabled}
          />
        </SimpleRow>
        <SimpleRow>
          <LabelInput
            label="Confirm Password"
            type="password"
            value={this.state.confirm}
            onChange={this.handleConfirmChange}
            disabled={this.props.disabled}
          />
        </SimpleRow>
        <SimpleButton
          expand={true}
          text={`${buttonText || DEFAULT_BUTTON_TEXT}`}
          disabled={this.props.disabled}
        />
      </form>
    )
  }
}

export default PasswordForm
