import React from 'react'
import './SignUpComplete.scss'
// Components:
import SimplePage from '../../SimplePage/SimplePage'

// This is shown once the user has successfully signed up.
function SignUpComplete (props) {
  return (
    <SimplePage title="Subscription created successfully">
      <p className="text-center">Your payment was processed successfully.</p>

      <a className="simple-button" href="#top" onClick={props.onComplete}>
        Login to HAZculator
      </a>
    </SimplePage>
  )
}

export default SignUpComplete
