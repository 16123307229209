import React from 'react'
import './FilterDropdown.scss'
import Select from 'react-select'

// A filterable drop-down.
class FilterDropdown extends React.Component {
  constructor (props) {
    super(props)
    this.element = React.createRef()
    this.state = {
      filter: ''
    }
  }

  handleFilter = (filter) => {
    this.setState({ filter })
  }

  handleClick = (event) => {
    this.setState({ filter: '' })
    this.props.onChange(event.value)
  }

  filteredOptions = () => {
    let text = this.state.filter.toLowerCase()
    let func = (i) => i.text.toLowerCase().indexOf(text) >= 0
    let result = this.props.options.filter(func).slice(0, 7)
    return result
  }

  classes = () => ['react-select-container', this.props.text ? 'value' : 'blank'].join(' ')

  dropdownInput = () => (
    <input
      className={this.classes()}
      value={this.state.filter}
      onChange={this.handleFilter}
      onBlur={this.handleBlur}
      placeholder={this.props.text || this.props.placeholder}
      autoComplete="off"
    />
  )

  optionResults = () => (
    this.filteredOptions().map(i => (
      { value: i.id, label: i.text }
    ))
  )

  render () {
    return (
      <label className="filter-dropdown" ref={this.element}>
        <span className="label">{this.props.label}</span>
        <Select
          className={this.classes()}
          classNamePrefix='react-select'
          value={this.state.filter}
          placeholder={this.props.text || this.props.placeholder}
          onChange={this.handleClick}
          onInputChange={this.handleFilter}
          options={this.optionResults() || []}
          noOptionsMessage={() => 'No matches found.'}
          isSearchable
          tabSelectsValue
          closeMenuOnSelect
          isClearable={false}
        />
      </label>
    )
  }
}

export default FilterDropdown
