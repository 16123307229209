import React from 'react'
import './LimitedToggle.scss'

// See https://yozudev.atlassian.net/browse/HU-18
function LimitedToggle (props) {
  // This determines visual appearance:
  const classes = ['limited-toggle', props.enabled ? 'on' : 'off'].join(' ')
  // This determines the descriptive text for assistive/accessibility software:
  const aria = props.enabled ? 'Undo Limited Quantity' : 'Set Limited Quantity'

  return (
    <button className={classes} onClick={props.onClick} aria-label={aria}>
      LTD QTY
    </button>
  )
}

export default LimitedToggle
