// NOTE: This excludes the special cases for 'exactly 1' and 'starts with 7'.
const CLASS_LABELS = [
  '1.1', '1.2', '1.3', '1.4B', '1.4C', '1.4D', '1.4E', '1.4F', '1.4G', '1.4S',
  '1.6N',
  '2.1', '2.2', '2.3',
  '3',
  '4.1', '4.2', '4.3',
  '5.1', '5.2',
  '6.1', '6.2',
  '8',
  '9'
]

// This determines which labels should be displayed for a given hazard.
class LabelGenerator {
  // ADR is not checked - see `app/views/dg/_dg_summary_body.html.erb` in FSv1.
  constructor (imdg) {
    this.classes = imdg.classes.map(c => c.name)
    this.packingGroup = imdg.packingGroup
    this.marinePollutant = imdg.marinePollutant
  }

  // See `placard_image_formatter` within `app/helpers/dg_helper.rb` in FSv1.
  labels = () => {
    let result = this.classes.map(c => this.classLabel(c))
    result.push(this.marineLabel())
    return result.filter(x => x)
  }

  // See `app/models/dg/labels/generator.rb` in FSv1.
  classLabel = (dgc) => {
    if (dgc === '1') { return 'class-1' }
    if (dgc.startsWith('7')) { return 'class-7_' + (this.packingGroup || 'X') }

    const result = CLASS_LABELS.find(i => dgc.startsWith(i))
    return result ? 'class-' + result.replace('.', '-') : null
  }

  // See `marine_pollutant_placard` within `app/helpers/dg_helper.rb` in FSv1.
  marineLabel = () => {
    if (this.marinePollutant === 'No') { return null }
    if (this.marinePollutant === 'Maybe') { return 'marine-pollutant-maybe' }
    return 'marine-pollutant' // 'Yes' or 'Yes, generic'
  }
}

export default LabelGenerator
