import React from 'react'
import './HazardIconSet.scss'
// Components:
import HazardIcon from '../HazardIcon/HazardIcon'

// See https://yozudev.atlassian.net/browse/HU-28
class HazardIconSet extends React.Component {
  iconFor = (title, restrictions) => {
    let severity = 'medium'

    if (restrictions === null) {
      severity = 'low'
    } else if (restrictions === 'PROHIBITED') {
      severity = 'high'
    }

    const details = restrictions || 'Not prohibited'
    return <HazardIcon title={title} severity={severity} details={details} />
  }

  adr = () => this.iconFor(this.props.hazard.adr.revision, this.props.hazard.adr.restrictions)
  imdg = () => this.iconFor(this.props.hazard.imdg.revision, this.props.hazard.imdg.restrictions)
  eurotunnel = () => this.iconFor('ET', this.props.hazard.adr.eurotunnel)

  // Unlike the other indicators, this is only shown when applicable:
  highConsequence = () => {
    const { hazard } = this.props
    const high = hazard.imdg.highConsequence || hazard.adr.highConsequence
    return high ? <HazardIcon title="High Consequence" severity="high" /> : null
  }

  classification = () => {
    const { hazard } = this.props
    const medium = hazard.adr.classificationCode
    return medium ? <HazardIcon title={medium} severity="medium" /> : null
  }

  // Unusually, we treat "with restrictions" as "PROHIBITED" for foodstuffs:
  foodstuffs = () => {
    if (this.props.hazard.imdg.foodstuffs || this.props.hazard.adr.foodstuffs) {
      return (
        <HazardIcon
          title="Foodstuffs"
          severity="high"
          details="NOT COMPATIBLE WITH FOODSTUFFS"
        />
      )
    } else {
      return (
        <HazardIcon
          title="Foodstuffs"
          severity="low"
          details="Compatible with foodstuffs"
        />
      )
    }
  }

  render () {
    return (
      <div className="hazard-icon-set">
        {this.highConsequence()}
        {this.classification()}
        {this.adr()}
        {this.imdg()}
        {this.eurotunnel()}
        {this.foodstuffs()}
      </div>
    )
  }
}

export default HazardIconSet
