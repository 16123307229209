import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image
} from '@react-pdf/renderer'

import logo from '../../../logo.png'

function CompletePdf (props) {
  const styles = StyleSheet.create({
    table: {
      display: 'table',
      width: 'auto',
      marginTop: '0',
      marginRight: '2cm',
      marginBottom: '0',
      marginLeft: '2cm',
      borderStyle: 'solid'
    },
    logo: {
      width: '3cm',
      left: '16cm',
      top: '2.5cm'
    },
    tableRow: {
      flexDirection: 'row',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderStyle: 'dotted',
      borderColor: '#999'
    },
    tableCol: {
      width: '5.67cm'
    },
    tableCell: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 12
    },
    tableHeader: {
      color: '#999',
      borderStyle: 'solid',
      borderWidth: 1
    },
    title: {
      marginTop: '2cm',
      marginLeft: '2cm',
      marginBottom: '1cm'
    }
  })

  const items = props.items.map(
    invoice => (
      <View style={styles.tableRow} key={invoice.id}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {`${invoice.date.day}/${invoice.date.month}/${invoice.date.year}`}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {invoice.currency}
            {invoice.amountDue}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {invoice.licenceCount}
          </Text>
        </View>
      </View>
    )
  )

  return (
    <Document>
      <Page>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.title}>Payment history</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Licences</Text>
            </View>
          </View>
          {items}
        </View>
      </Page>
    </Document>
  )
}

export default CompletePdf
