import React from 'react'
import './SubscriptionForm.scss'
// Components:
import StripeContainer from '../../Stripe/StripeContainer'

// This allows the user to change their payment details.
function SubscriptionForm (props) {
  return (
    <div className="subscription-form">
      <StripeContainer
        token={props.token}
        onSuccess={props.onSuccess}
        onError={props.onError}
        successMessage={props.successMessage}
      />
    </div>
  )
}

export default SubscriptionForm
