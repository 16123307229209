import React from 'react'
// Components:
import GenericSection from '../../../common/GenericSection/GenericSection'
import CompatibilityContextList from './CompatibilityContextList'

class CompatibilityDetail extends React.Component {
  handleDropdownToggle = () => (
    this.props.handleDropdownToggle(this.props.idx)
  )

  head = () => {
    const { hazard1, hazard2 } = this.props.detail
    return (
      <div className="clash-head">
        Clashes between {hazard1} and {hazard2}
      </div>
    )
  }

  body = () => (
    <CompatibilityContextList contexts={this.props.detail.contexts} />
  )

  render () {
    return (
      <GenericSection
        head={this.head()}
        body={this.body()}
        expanded={this.props.detail.expanded}
        onDropdownToggle={this.handleDropdownToggle}
      />
    )
  }
}

export default CompatibilityDetail
