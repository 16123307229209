import React from 'react'
import './LogoutButton.scss'

// This represents the "logout" button within the main screen.
function LogoutButton (props) {
  const handler = (e) => {
    e.preventDefault()
    props.onClick()
  }

  return <button className="logout-button" onClick={handler}>Log out</button>
}

export default LogoutButton
