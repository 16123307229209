import React from 'react'
// Components:
import DataList from '../../common/DataList/DataList'
// Utilities:
import PrettyPrint from './PrettyPrint'

// This represents a single item within the list of currently-added items.
function HazardousItemSummary (props) {
  const data = {
    'Description': PrettyPrint.fullDescription(props.un, props.imdg, props.adr),
    'Stowage Category (IMDG)': PrettyPrint.stowageCategory(props.imdg),
    'Compatibilities (IMDG)': props.imdg.compatibility,
    'Compatibilities (ADR)': props.adr.compatibility,
    'Foodstuff Segregation (IMDG)': PrettyPrint.foodstuffs(props.imdg),
    'Foodstuff Segregation (ADR)': PrettyPrint.foodstuffs(props.adr),
    'Transport Category (ADR)': PrettyPrint.transportCategory(props.adr),
    'Eurotunnel': PrettyPrint.eurotunnel(props.adr)
  }

  return <DataList data={data} />
}

export default HazardousItemSummary
