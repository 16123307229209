import React from 'react'
import './HazculatorForm.scss'
// Components:
import CompatibilityResult from '../CompatibilityResult/CompatibilityResult'
import HazardousItemList from '../HazardousItemList/HazardousItemList'
import SearchForm from '../SearchForm/SearchForm'
// APIs:
import HAZculator from '../../apis/HAZculator'
import GoogleAnalytics from '../../apis/GoogleAnalytics'
// This represents the "main" HAZculator screen.
class HazculatorForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      items: JSON.parse(sessionStorage.getItem('items')) || []
    }
  }

  handleAdd = (imdg, adr) => {
    imdg = Number(imdg)
    adr = Number(adr)
    if (this.state.items.find((i) => i.imdg.id === imdg && i.adr.id === adr)) {
      return this.props.onError('This IMDG/ADR combination already exists')
    }

    HAZculator.details(this.props.token, imdg, adr).then(
      json => {
        json = Object.assign(json, { limitedQuantity: false, expanded: false })
        this.setState({ items: this.state.items.concat([json]) })
        sessionStorage.setItem('items', JSON.stringify(this.state.items))
        this.props.onError(null)
        GoogleAnalytics.addCode()
      },
      error => this.props.onError(error)
    )
  }

  handleLimitedQuantityToggle = (idx) => {
    const items = this.state.items.slice()
    items[idx] = { ...items[idx], limitedQuantity: !items[idx].limitedQuantity }
    this.setItems(items)
  }

  handleDropdownToggle = (idx) => {
    const items = this.state.items.map((item, index) => {
      const expanded = (index !== idx) ? false : !item.expanded
      return { ...item, expanded }
    })
    this.setItems(items)
  }

  setItems = (items) => {
    this.setState({ items })
    sessionStorage.setItem('items', JSON.stringify(items))
  }

  handleRemove = (idx) => {
    const items = this.state.items.slice()
    items.splice(idx, 1)
    this.setItems(items)
    this.props.onError(null)
    GoogleAnalytics.removeCode()
  }

  handleReset = () => {
    this.setState({ items: [] })
    sessionStorage.clear()
    this.props.onError(null)
    GoogleAnalytics.resetCodes()
  }

  render () {
    return (
      <div className="hazculator-form">
        <SearchForm
          onAdd={this.handleAdd}
          onReset={this.handleReset}
          onError={this.props.onError}
          token={this.props.token}
          version={this.props.version}
          disableReset={this.state.items.length === 0}
        />
        <CompatibilityResult
          items={this.state.items}
          token={this.props.token}
          onError={this.props.onError}
        />
        <HazardousItemList
          items={this.state.items}
          handleDropdownToggle={this.handleDropdownToggle}
          handleLimitedQuantityToggle={this.handleLimitedQuantityToggle}
          onRemove={this.handleRemove}
        />
      </div>
    )
  }
}

export default HazculatorForm
