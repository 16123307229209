import React from 'react'
// Components:
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage'
import HazardLabelSet from '../HazardLabelSet/HazardLabelSet'
import HazardousItemAdr from './HazardousItemAdr'
import HazardousItemImdg from './HazardousItemImdg'
import HazardousItemSummary from './HazardousItemSummary'
// Utilities:
import PrettyPrint from './PrettyPrint'

// This represents a single item within the list of currently-added items.
function HazardousItemBody (props) {
  return (
    <React.Fragment>
      <ErrorMessage text={PrettyPrint.highConsequence(props.imdg, props.adr)} />
      <ErrorMessage text={PrettyPrint.consultDGSA(props.imdg, props.adr)} />
      <ErrorBoundary>
        <HazardousItemSummary {...props} />
      </ErrorBoundary>
      <ErrorBoundary>
        <HazardLabelSet imdg={props.imdg} />
      </ErrorBoundary>
      <ErrorBoundary>
        <HazardousItemImdg imdg={props.imdg} />
      </ErrorBoundary>
      <ErrorBoundary>
        <HazardousItemAdr adr={props.adr} />
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default HazardousItemBody
