/* eslint prefer-promise-reject-errors: 0 */

// This class handles all interaction with the Organisation Management API.
class AccountManagement {
  static activeCoupon (jwt) {
    return this.post('coupons/active.json', {}, jwt)
  }

  static searchCoupon (code) {
    return this.post('coupons/search.json', { code: code })
  }

  static priceTiers () {
    return this.post('subscriptions/prices.json', {})
  }

  static fetchOrganisation (jwt) {
    return this.post('organisations/status.json', {}, jwt)
  }

  static createOrganisation (params) {
    return this.post('organisations/create.json', params)
  }

  static updateOrganisation (params, jwt) {
    return this.post('organisations/update.json', params, jwt)
  }

  static destroyOrganisation (jwt) {
    return this.post('organisations/delete.json', {}, jwt)
  }

  static emailPassword (email) {
    return this.post('passwords/email.json', { email: email })
  }

  static resetPassword (resetToken, password) {
    const params = { resetToken: resetToken, password: password }
    return this.post('passwords/reset.json', params)
  }

  static fetchSubscription (jwt) {
    return this.post('subscriptions/status.json', {}, jwt)
  }

  static createSubscription (stripeToken, jwt, promoCode) {
    const params = this.subscriptionParams(stripeToken, promoCode)
    return this.post('subscriptions/create.json', params, jwt)
  }

  static updateSubscription (stripeToken, jwt) {
    const params = this.subscriptionParams(stripeToken)
    return this.post('subscriptions/update.json', params, jwt)
  }

  static subscriptionHistory (jwt) {
    return this.post('subscriptions/history.json', {}, jwt)
  }

  static updateLicences (params, jwt) {
    return this.post('subscriptions/update_licences.json', params, jwt)
  }

  static activeUser (jwt) {
    return this.post('users/active.json', {}, jwt)
  }

  static otherUsers (jwt) {
    return this.post('users/others.json', {}, jwt)
  }

  static createUser (params, jwt) {
    return this.post('users/create.json', params, jwt)
  }

  static updateUser (params, jwt) {
    return this.post('users/update.json', params, jwt)
  }

  static deleteUser (id, jwt) {
    return this.post('users/delete.json', { id: id }, jwt)
  }

  static post (path, params, jwt) {
    return new Promise((resolve, reject) => {
      const server = process.env.REACT_APP_ACCOUNT_MANAGEMENT_SERVER

      const contentType = 'application/x-www-form-urlencoded; charset=UTF-8'

      let xhr = new XMLHttpRequest()
      xhr.open('POST', [server, path].join('/'))
      if (jwt) { xhr.setRequestHeader('Authorization', ['Bearer', jwt.access_token].join(' ')) }
      xhr.setRequestHeader('Content-Type', contentType)

      let data = new URLSearchParams()
      Object.keys(params).forEach(k => {
        if (params[k] !== undefined) { data.set(k, params[k]) }
      })
      xhr.send(data)

      xhr.onload = () => {
        const json = xhr.response ? JSON.parse(xhr.response) : null
        xhr.status === 200 ? resolve(json) : reject(json.error)
      }

      xhr.onerror = () => { reject('The account management server is offline') }
      xhr.onabort = () => { reject('The request was aborted') }
      xhr.ontimeout = () => { reject('The request timed out') }
    })
  }

  static subscriptionParams (json, promoCode) {
    let description = ''
    if (json.card) { description = json.card.last4 }

    const code = promoCode || '' // Do not pass `null` through as `"null"`!
    return { stripeId: json.id, description: description, promoCode: code }
  }
}

export default AccountManagement
