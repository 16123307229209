import CostCalculator from './CostCalculator'

// Common coupon text stuff.
class CouponHelper {
  static durationText (coupon) {
    return coupon.durationMonths ? `${coupon.durationMonths} months` : 'Forever'
  }

  static discountText (coupon) {
    if (coupon.discountKind === 'percent') {
      return `-${coupon.discountValue}%`
    }

    const value = coupon.discountValue / 100 // Assume we're not using Yen. :)
    return `-${CostCalculator.currency(coupon)}${value.toFixed(2)}`
  }
}

export default CouponHelper
