const LQ_SUFFIX = [
  'inner receptacle  (maximum outer package 30kgs gross, or 20kgs if glass',
  'inners). If goods are packed in an Overpack, consult IMDG manual.'
].join(' ')

const EQ_SUFFIX = [
  '(in grams for solids, and ml for liquids & gases combined sum if mixed',
  'packing)'
].join(' ')

const ET_PROHIBITED = [
  'Not permitted to travel on Eurotunnel',
  '(except in Limited or Excepted Quantities unless otherwise stated)'
].join(' ')

class PrettyPrint {
  static packingGroup (imdgOrAdr) {
    return imdgOrAdr.packingGroup || '-'
  }

  static primaryClass (imdgOrAdr) {
    return imdgOrAdr.classes.find(i => i.primary).name
  }

  static secondaryClasses (imdgOrAdr) {
    return imdgOrAdr.classes.filter(i => !i.primary).map(i => i.name).join(', ')
  }

  static classes (imdgOrAdr) {
    const primary = this.primaryClass(imdgOrAdr)
    const others = this.secondaryClasses(imdgOrAdr)
    return others ? primary + ' (' + others + ')' : primary
  }

  static treatAsClass (imdg) {
    return imdg.treatAsClass ? imdg.treatAsClass.name : '-'
  }

  static foodstuffs (imdgOrAdr) {
    return imdgOrAdr.foodstuffs || 'None'
  }

  static specialProvisions (imdgOrAdr) {
    const func = (i) => 'SP' + i.code
    return imdgOrAdr.specialProvisions.map(func).join(', ') || 'None'
  }

  static limitedQuantity (imdgOrAdr) {
    const lq = imdgOrAdr.limitedQuantity
    if (!lq) { return 'Not permitted in Limited Quantities' }
    return [lq.value, lq.unit, ' ', LQ_SUFFIX].join('')
  }

  static exceptedQuantity (imdgOrAdr) {
    const eq = imdgOrAdr.exceptedQuantity
    if (!eq) { return '-' }
    if (eq.code === 'E0') { return eq.text }
    return [eq.text, EQ_SUFFIX].join(' ')
  }

  static tunnelCodeName (adr) {
    return adr.tunnelCode ? adr.tunnelCode.code : '-'
  }

  static tunnelCodeInfo (adr) {
    return adr.tunnelCode ? adr.tunnelCode.text : '-'
  }

  static classificationCode (adr) {
    return adr.classificationCode || '-'
  }

  static transportCategory (adr) {
    const category = adr.transportCategory
    return category ? [category.code, category.text].join(' - ') : 'None'
  }

  static stowageCategory (imdg) {
    const category = imdg.stowageCategory
    return category ? [category.code, category.text].join(' - ') : 'None'
  }

  static flashpoint (imdg) {
    return imdg.flashpoint || '-'
  }

  // This can be used for C-codes, CV-codes, or S-codes.
  static adrCodes (adrCodeList) {
    return adrCodeList.map(i => i.code).join(', ') || '-'
  }

  static segregationGroups (imdg) {
    return imdg.segregationGroups.map(i => i.text).join(', ') || '-'
  }

  static segregationInstructions (imdg) {
    return imdg.segregationInstructions.map(i => i.text).join('\n') || '-'
  }

  static eurotunnel (adr) {
    if (adr.eurotunnel === null) { return 'No restriction' }
    return adr.eurotunnel === 'PROHIBITED' ? ET_PROHIBITED : adr.eurotunnel
  }

  static highConsequence (imdg, adr) {
    const t = 'HIGH CONSEQUENCE GOODS - consult DGSAs before accepting booking'
    return imdg.highConsequence || adr.highConsequence ? t : null
  }

  static consultDGSA (imdg, adr) {
    const t = 'CLASS 1 AND/OR CLASS 7 - consult DGSAs before accepting booking'

    let classes = imdg.classes.concat(adr.classes).map(c => c.name)
    classes = classes.filter(c => c.name !== '1.4S') // Special case; ignored.
    return classes.find(c => c.startsWith('1') || c.startsWith('7')) ? t : null
  }

  // See https://yozudev.atlassian.net/browse/HU-50
  static imdgDescription (un, imdg) {
    return [
      un,
      imdg.psn,
      this.classes(imdg),
      this.packingGroup(imdg),
      this.flashpoint(imdg)
    ].join(' ')
  }

  // See https://yozudev.atlassian.net/browse/HU-50
  static adrDescription (un, adr) {
    return [
      un,
      adr.psn,
      this.classes(adr),
      this.packingGroup(adr),
      this.tunnelCodeName(adr)
    ].join(' ')
  }

  // See https://yozudev.atlassian.net/browse/HU-50
  static fullDescription (un, imdg, adr) {
    return [this.adrDescription(un, adr), this.flashpoint(imdg)].join(' ')
  }
}

export default PrettyPrint
