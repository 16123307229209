import React from 'react'
import './ForgotPasswordPage.scss'
// Components:
import SimplePage from '../../SimplePage/SimplePage'
import LabelInput from '../../../common/LabelInput/LabelInput'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// Allows a user to request a password reset.
class ForgotPasswordPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { email: '', error: null }
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ show: 'loading' })

    AccountManagement.emailPassword(this.state.email).then(
      json => this.setState({ show: 'complete' }),
      error => this.setState({ show: null, error: error })
    )
  }

  render () {
    if (this.state.show === 'complete') {
      return (
        <SimplePage title="Reset request processed">
          <div>An email has been sent to {this.state.email}</div>
        </SimplePage>
      )
    }

    return (
      <div className="forgot-password-page">
        <SimplePage error={this.state.error} title="Request Password Reset">
          <form onSubmit={this.handleSubmit}>
            <LabelInput
              label="Email Address"
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              disabled={this.state.show === 'loading'}
            />
            <SimpleButton
              text="Send Email"
              expand={true}
              disabled={this.state.show === 'loading'}
            />
          </form>
          <SimpleButton
            expand={true}
            text="Cancel"
            theme="muted"
            onClick={this.props.onCancel}
          />
        </SimplePage>
      </div>
    )
  }
}

export default ForgotPasswordPage
