import React from 'react'
import './UserForm.scss'
// Components:
import LabelInput from '../../../common/LabelInput/LabelInput'
import PasswordForm from '../../Password/PasswordForm/PasswordForm'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimpleRow from '../../../common/SimpleRow/SimpleRow'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// This is used to update the current user, or to create/update another one.
class UserForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: props.user.id,
      email: props.user.email || '',
      firstName: props.user.firstName,
      lastName: props.user.lastName
    }
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  handleDirectSubmit = (e) => {
    e.preventDefault()
    this.handleSubmit('')
  }

  handleSuccessMessage = (json, action) => {
    if (json.warnings) {
      return (
        json.warnings.length === 1 && json.warnings[0].includes('Failed to send email to')
          ? this.successMessage(action) : null
      )
    }
    return this.successMessage(action)
  }

  successMessage = (action) => (
    this.props.successMessage(
      action === 'updateUser' ? 'User has been updated.' : 'User has been created.'
    )
  )

  handleWarnings = (json) => (
    json.warnings ? this.handleError(json.warnings.join(', ')) : null
  )

  handleError = (error) => {
    this.setState({ loading: false })
    this.props.onError(error)
  }

  clearMessages = () => {
    this.props.successMessage(null)
    this.props.onError(null)
  }

  handleSubmit = (password) => {
    const action = this.state.id ? 'updateUser' : 'createUser'
    const params = { ...this.state, password }

    this.setState({ loading: true })
    this.clearMessages()

    AccountManagement[action](params, this.props.token).then(
      json => {
        this.setState({ loading: false })
        this.props.onSuccess(json)
        this.handleSuccessMessage(json, action)
        this.handleWarnings(json)
      },
      this.handleError
    )
  }

  render () {
    const basicFields = (
      <React.Fragment>
        <SimpleRow>
          <LabelInput
            label="First name"
            disabled={this.state.loading}
            value={this.state.firstName}
            onChange={this.handleFirstNameChange}
          />
          <LabelInput
            label="Last name"
            disabled={this.state.loading}
            value={this.state.lastName}
            onChange={this.handleLastNameChange}
          />
        </SimpleRow>
        <SimpleRow>
          <LabelInput
            label="Email"
            disabled={this.state.loading}
            type="email"
            value={this.state.email}
            required={true}
            onChange={this.handleEmailChange}
          />
        </SimpleRow>
      </React.Fragment>
    )

    if (this.props.includePassword) {
      return (
        <PasswordForm
          onSubmit={this.handleSubmit}
          onError={this.props.onError}

          disabled={this.state.loading}>
          {basicFields}
        </PasswordForm>
      )
    }

    const buttonText = this.state.id ? 'Update user details' : 'Add new user'

    return (
      <React.Fragment>
        <form onSubmit={this.handleDirectSubmit}>
          {basicFields}
          <small className="user-form-disclaimer">
            By clicking &quot;{buttonText}&quot; you agree to our
            <a target="_blank" href="/static/terms.html"> terms</a>.
          </small>
          <SimpleButton
            disabled={this.state.loading}
            expand={true}
            text={buttonText}
          />
        </form>
        <SimpleButton
          disabled={this.state.loading}
          text="Cancel"
          theme="muted"
          expand={true}
          onClick={this.props.onCancel}
        />
        { this.state.id && (
          <div className="edit-user-delete-button">
            <SimpleButton
              expand={true}
              theme="muted"
              text="Delete"
              onClick={() => this.props.handleDelete(this.state)} />
          </div>)}
      </React.Fragment>
    )
  }
}

export default UserForm
