import React from 'react'
// Components:
import ChangePasswordPage from './account/Password/ChangePasswordPage/ChangePasswordPage'
import CreateSubscription from './account/SignUp/CreateSubscription/CreateSubscription'
import CreateOrganisation from './account/SignUp/CreateOrganisation/CreateOrganisation'
import AddLicences from './account/SignUp/AddLicences/AddLicences'
import ForgotPasswordPage from './account/Password/ForgotPasswordPage/ForgotPasswordPage'
import HazculatorPage from './hazculator/HazculatorPage/HazculatorPage'
import LoginForm from './account/LoginForm/LoginForm'
import SettingsPage from './account/SettingsPage/SettingsPage'
import SignUpComplete from './account/SignUp/SignUpComplete/SignUpComplete'
// APIs:
import AccountManagement from './apis/AccountManagement'
import KeyCloak from './apis/KeyCloak'
import GoogleAnalytics from './apis/GoogleAnalytics'
import { setCookie, getCookie } from './apis/Cookies'

// This is the main "application" component, and basically just handles JWTs.
class App extends React.Component {
  constructor (props) {
    super(props)
    const screen = this.getScreen()
    const token = this.getToken()
    this.state = { token, error: null, success: null, screen }
  }

  componentDidMount () {
    if (process.env.NODE_ENV === 'production') {
      GoogleAnalytics.initialize('UA-120389708-2')
    }
  }

  componentDidUpdate (_, prevState) {
    if (prevState.screen !== this.state.screen) {
      GoogleAnalytics.pageview(this.state.screen || 'login')
    }
  }

  getScreen = () => (
    sessionStorage.getItem('screen') ? sessionStorage.getItem('screen') : this.handlePassword()
  )

  storeScreen = () => (
    sessionStorage.setItem('screen', this.state.screen)
  )

  getToken = () => (
    getCookie('token') ? JSON.parse(getCookie('token')) : null
  )

  storeToken = () => (
    setCookie('token', this.state.token)
  )

  handlePassword = () => (
    this.passwordResetToken() ? 'changePasswordPage' : null
  )

  passwordResetToken = () => {
    return new URLSearchParams(window.location.search).get('reset_token')
  }

  handleError = (e) => {
    if (e === "Your account doesn't have permission to access this service.") {
      return this.handleAccessDenied()
    }

    if (e === 'You need to sign in or sign up before continuing.') {
      this.handleInvalidToken()
    }

    if (e === 'Missing parameter: username') {
      return 'Please enter a valid email address.'
    }

    if (e === 'Invalid user credentials') {
      return 'Invalid username/password.'
    }

    this.setState({ error: e })
  }

  handleAccessDenied = () => {
    AccountManagement.activeUser(this.state.token).then(
      json => this.accessDeniedNextAction(json.owner ? 'Y' : 'N'),
      () => this.accessDeniedNextAction(null) // No idea if they're an owner.
    )
  }

  handleSuccess = (success) => {
    this.setState({ success })
  }

  accessDeniedNextAction = (owner) => {
    if (owner === 'Y') {
      this.setState({ screen: 'settings' })
    } else if (owner === 'N') {
      this.setState({ error: 'Access denied; contact an organisation admin.' })
    } else {
      this.setState({ error: 'Something went wrong; please try again later.' })
    }
  }

  // Check with KeyCloak, to see if the token really has expired...
  handleInvalidToken = () => {
    KeyCloak.currentUser(this.state.token).then(
      () => this.setState({ error: 'The HAZculator server is misconfigured.' }),
      () => this.refreshAccessToken() // ...and, if so, attempt to replace it.
    )
  }

  handleSignIn = (username, password, nextScreen) => {
    KeyCloak.signIn(username, password).then(
      token => this.setState({ error: null, success: null, token, screen: nextScreen },
        () => {
          this.storeToken()
          this.storeScreen()
          GoogleAnalytics.login()
        }),
      error => {
        error = this.handleError(error)
        this.setState({ error, token: null, screen: null })
      }
    )
  }

  handleSignOut = () => {
    KeyCloak.signOut(this.state.token).then(
      () => this.setState({ error: null, token: null, screen: null }),
      error => this.setState({ error: error, token: null, screen: null })
    )
  }

  handleSettings = () => {
    this.setState({ screen: 'settings', error: null, success: null },
      () => { this.storeScreen() })
  }

  handleHazculator = () => {
    this.setState({ screen: 'hazculator', error: null },
      () => { this.storeScreen() })
  }

  handleCreateOrganisation = () => {
    this.setState({ screen: 'createOrganisation', error: null },
      () => { this.storeScreen() })
  }

  handleCreateSubscription = () => {
    this.setState({ screen: 'createSubscription' },
      () => { this.storeScreen() })
  }

  handleSignUpComplete = () => {
    this.setState({ screen: 'signUpComplete' },
      () => {
        this.storeScreen()
        GoogleAnalytics.signup()
      })
  }

  handleCancelSignUp = () => {
    this.setState({ screen: null },
      () => { this.storeScreen() })
  }

  handleForgotPassword = () => {
    this.setState({ screen: 'forgotPasswordPage', error: null },
      () => { this.storeScreen() })
  }

  handleChangePassword = () => {
    window.history.replaceState({}, 'HAZculator', '/') // Remove ?reset_token=X
    this.setState({ screen: null },
      () => { this.storeScreen() })
  }

  refreshAccessToken = () => {
    // TODO: See https://yozudev.atlassian.net/browse/HU-12
    this.setState({ error: 'Your access token has expired.', token: null, screen: null })
  }

  hazculatorPage = () => (
    <HazculatorPage
      error={this.state.error}
      token={this.state.token}
      onError={this.handleError}
      onSettings={this.handleSettings}
      onSignOut={this.handleSignOut}
    />
  )

  settingsPage = () => (
    <SettingsPage
      error={this.state.error}
      success={this.state.success}
      token={this.state.token}
      onError={this.handleError}
      onSuccess={this.handleSuccess}
      onHazculator={this.handleHazculator}
      onSignOut={this.handleSignOut}
    />
  )

  createOrganisation = () => (
    <CreateOrganisation
      onComplete={this.handleSignIn}
      onCancel={this.handleCancelSignUp}
    />
  )

  addLicences = () => (
    <AddLicences
      onComplete={this.handleCreateSubscription}
      onCancel={this.handleCancelSignUp}
      token={this.state.token}
    />
  )

  createSubscription = () => (
    <CreateSubscription
      onComplete={this.handleSignUpComplete}
      onSuccess={this.handleSuccess}
      onCancel={this.handleCancelSignUp}
      token={this.state.token}
    />
  )

  signUpComplete = () => (
    <SignUpComplete
      onComplete={this.handleSignOut} // We need a new access token!
      token={this.state.token}
    />
  )

  forgotPasswordPage = () => (
    <ForgotPasswordPage
      onCancel={this.handleCancelSignUp}
    />
  )

  changePasswordPage = () => (
    <ChangePasswordPage
      resetToken={this.passwordResetToken()}
      onSuccess={this.handleChangePassword}
    />
  )

  loginForm = () => (
    <LoginForm
      onSubmit={this.handleSignIn}
      error={this.state.error}
      onCreate={this.handleCreateOrganisation}
      onForgot={this.handleForgotPassword}
    />
  )

  render () {
    switch (this.state.screen) {
      case 'hazculator': return this.hazculatorPage()
      case 'settings': return this.settingsPage()
      case 'createOrganisation': return this.createOrganisation()
      case 'addLicences': return this.addLicences()
      case 'createSubscription': return this.createSubscription()
      case 'signUpComplete': return this.signUpComplete()
      case 'forgotPasswordPage': return this.forgotPasswordPage()
      case 'changePasswordPage': return this.changePasswordPage()
      default: return this.loginForm()
    }
  }
}

export default App
