import React from 'react'
import './CancelButton.scss'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'

// The Big Red Button.
class CancelButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = { loading: false }
  }

  handleLoading = () => {
    this.setState(prev => ({ loading: !prev.loading }))
  }

  deleteRequest = () => (
    AccountManagement.destroyOrganisation(this.props.token).then(
      json => {
        this.props.onSuccess(json)
        this.setState({ loading: false })
      },
      error => {
        this.props.onError(error)
        this.setState({ loading: false })
      }
    )
  )

  deleteOptions = () => (
    <React.Fragment>
      <span>Are you sure you want to delete your account?</span>
      <div className="delete-options">
        <SimpleButton
          expand={true}
          theme="cancel"
          text="Cancel"
          onClick={this.handleLoading} />
        <SimpleButton
          expand={true}
          theme="warning"
          text="Delete"
          onClick={this.deleteRequest} />
      </div>
    </React.Fragment>
  )

  deleteButton = () => {
    const disabled = this.state.loading || !this.props.user.owner || this.props.disabled
    return (
      <button
        className="cancel-button delete-account"
        disabled={disabled}
        onClick={this.handleLoading}>
        Delete Account
      </button>
    )
  }

  render () {
    return (
      this.state.loading ? this.deleteOptions() : this.deleteButton()
    )
  }
}

export default CancelButton
