import React from 'react'
import './CreateOrganisation.scss'
// Components:
import LabelInput from '../../../common/LabelInput/LabelInput'
import PasswordForm from '../../Password/PasswordForm/PasswordForm'
import SignUpProgress from '../SignUpProgress/SignUpProgress'
import SimpleButton from '../../../common/SimpleButton/SimpleButton'
import SimplePage from '../../SimplePage/SimplePage'
import SimpleRow from '../../../common/SimpleRow/SimpleRow'
import PleaseWait from '../../../common/PleaseWait/PleaseWait'
// APIs:
import AccountManagement from '../../../apis/AccountManagement'

// This handles the first screen of the sign-up process.
class CreateOrganisation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      organisation: '',
      firstName: '',
      lastName: '',
      email: '',
      error: null,
      loading: false
    }
  }

  handleError = (error) => {
    this.setState({ error: error, loading: false })
  }

  handleOrganisationChange = (event) => {
    this.setState({ organisation: event.target.value })
  }

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = (password) => {
    const params = { ...this.state, password: password }

    this.setState({ loading: true })
    AccountManagement.createOrganisation(params).then(
      () => this.props.onComplete(this.state.email, password, 'addLicences'),
      this.handleError
    )
  }

  passwordForm = () => (
    <PasswordForm
      onSubmit={this.handleSubmit}
      onError={this.handleError}
      disabled={this.state.loading}
      buttonText="Create Account"
    >
      <SimpleRow>
        <LabelInput
          label="Organisation name"
          disabled={this.state.loading}
          value={this.state.organisation}
          required={true}
          onChange={this.handleOrganisationChange}
        />
      </SimpleRow>
      <SimpleRow>
        <LabelInput
          label="First name"
          disabled={this.state.loading}
          value={this.state.firstName}
          onChange={this.handleFirstNameChange}
        />
        <LabelInput
          label="Last name"
          disabled={this.state.loading}
          value={this.state.lastName}
          onChange={this.handleLastNameChange}
        />
      </SimpleRow>
      <SimpleRow>
        <LabelInput
          label="Email"
          disabled={this.state.loading}
          value={this.state.email}
          required={true}
          type="email"
          onChange={this.handleEmailChange}
        />
      </SimpleRow>
    </PasswordForm>
  )

  simpleButton = () => (
    <SimpleButton
      disabled={this.state.loading}
      expand={true}
      text="Already have an account? Log in"
      theme="muted"
      onClick={this.props.onCancel}
    />
  )

  questionsMessage = () => (
    <div className="terms-and-questions-text">
      <span>
        If you have any questions or queries regarding this
        product, please do not hesitate to contact us on
      </span>
      <a href="mailto:hello@hazculator.co.uk"> hello@hazculator.co.uk</a>
      <span> or visit our website by clicking</span>
      <a target="_blank" rel="noopener noreferrer" href="https://hazculator.co.uk"> here</a>
    </div>
  )

  termsOfService = () => (
    <div className="terms-and-questions-text">
      <span>By creating your account, you agree to the <br/></span>
      <a target="_blank" href="/static/terms.html">
        HAZculator Terms of Service
      </a>
      <span> and </span>
      <a target="_blank" href="/static/privacy.html">
        Privacy Policy
      </a>
    </div>
  )

  render () {
    return (
      <div className="create-organisation">
        <SimplePage
          error={this.state.error}
          title="Create Your Organisation Account"
          header={<SignUpProgress step={0} />}
        >
          { this.state.loading ? <PleaseWait /> : <this.passwordForm /> }
          <this.simpleButton />
          <this.questionsMessage />
          <this.termsOfService />
        </SimplePage>
      </div>
    )
  }
}

export default CreateOrganisation
