import ReactGA from 'react-ga' // Google Analytics

export default {
  initialize () {
    ReactGA.initialize('UA-120389708-2')
  },
  pageview (screen) {
    ReactGA.pageview(screen)
  },
  login () {
    ReactGA.event({ category: 'User', action: 'Logged in' })
  },
  signup () {
    ReactGA.event({ category: 'User', action: 'Signed up' })
  },
  addCode () {
    ReactGA.event({ category: 'HAZculation', action: 'Added a code' })
  },
  removeCode () {
    ReactGA.event({ category: 'HAZculation', action: 'Removed a code' })
  },
  resetCodes () {
    ReactGA.event({ category: 'HAZculation', action: 'Reset all codes' })
  }
}
