import React from 'react'
// Components:
import FilterDropdown from '../../common/FilterDropdown/FilterDropdown'
// APIs:
import HAZculator from '../../apis/HAZculator'

// This represents the specialised "UN search" dropdown.
class UnNumberDropdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = { options: [] }
  }

  componentDidUpdate (prevProps) {
    if (this.props.version === prevProps.version) { return }

    HAZculator.search(this.props.token, this.props.version).then(
      json => this.setState({ options: json }),
      error => this.props.onError(error)
    )
  }

  render () {
    const selected = this.state.options.find(i => i.id === this.props.value)
    const text = selected ? selected.text.split('-')[0] : null

    return (
      <FilterDropdown
        label="UN Number"
        placeholder="Search by PSN or UN number..."
        text={text}
        options={this.state.options}
        onChange={this.props.onChange}
      />
    )
  }
}

export default UnNumberDropdown
