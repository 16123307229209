import React from 'react'
import './HazardLabelSet.scss'
// Components:
import HazardLabel from '../HazardLabel/HazardLabel'
// Utilities:
import LabelGenerator from './LabelGenerator'

// This represents the various images applicable to a hazard.
function HazardLabelSet (props) {
  const items = new LabelGenerator(props.imdg).labels()
  const labels = items.map(i => <HazardLabel key={i} kind={i} />)
  return <div className="hazard-label-set">{labels}</div>
}

export default HazardLabelSet
